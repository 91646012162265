import { useIntl } from "react-intl";

import useMe from "@hooks/useMe";
import { timezoneAbbreviation } from "@utils/timeUtils";

const useGetMyTimeWithTimezoneAbbreviation = (showTimezone = true) => {
  const me = useMe();
  const intl = useIntl();

  return (time: Date | undefined) => {
    if (me?.timezone) {
      return `${intl.formatTime(time)} ${showTimezone ? (timezoneAbbreviation(me.timezone, time) ?? "") : ""}`;
    }

    return intl.formatTime(time, { timeZoneName: showTimezone ? "short" : undefined });
  };
};

export default useGetMyTimeWithTimezoneAbbreviation;
