import apple from "@assets/icons/brands/apple.svg";
import facebookF from "@assets/icons/brands/facebook-f.svg";
import linkedinIn from "@assets/icons/brands/linkedin-in.svg";
import twitter from "@assets/icons/brands/twitter.svg";
import generalCircle from "@assets/icons/community/group-general-circle.svg";
import general from "@assets/icons/community/group-general-icon.svg";
import circleDot from "@assets/icons/regular/circle-dot.svg";
import circle from "@assets/icons/regular/circle.svg";
import heartOutline from "@assets/icons/regular/heart.svg";
import thumbtackOutline from "@assets/icons/regular/thumbtack.svg";
import addressCard from "@assets/icons/solid/address-card.svg";
import alarmClock from "@assets/icons/solid/alarm-clock.svg";
import arrowLeft from "@assets/icons/solid/arrow-left.svg";
import arrowRightFromBracket from "@assets/icons/solid/arrow-right-from-bracket.svg";
import arrowRight from "@assets/icons/solid/arrow-right.svg";
import at from "@assets/icons/solid/at.svg";
import badgeCheck from "@assets/icons/solid/badge-check.svg";
import barFiltersDuo from "@assets/icons/solid/bars-filter-duo.svg";
import barFiltersNone from "@assets/icons/solid/bars-filter-none.svg";
import barFilters from "@assets/icons/solid/bars-filter.svg";
import basketShopping from "@assets/icons/solid/basket-shopping.svg";
import bell from "@assets/icons/solid/bell.svg";
import bold from "@assets/icons/solid/bold.svg";
import bookCopy from "@assets/icons/solid/book-copy.svg";
import bookHeart from "@assets/icons/solid/book-heart.svg";
import bookOpen from "@assets/icons/solid/book-open.svg";
import book from "@assets/icons/solid/book.svg";
import bookmark from "@assets/icons/solid/bookmark.svg";
import boxArchive from "@assets/icons/solid/box-archive.svg";
import bridgeSuspension from "@assets/icons/solid/bridge-suspension.svg";
import briefcaseMedical from "@assets/icons/solid/briefcase-medical.svg";
import building from "@assets/icons/solid/building.svg";
import bullseyeArrow from "@assets/icons/solid/bullseye-arrow.svg";
import calendarCheck from "@assets/icons/solid/calendar-check.svg";
import calendarClock from "@assets/icons/solid/calendar-clock.svg";
import calendarDay from "@assets/icons/solid/calendar-day.svg";
import calendarDays from "@assets/icons/solid/calendar-days.svg";
import calendarExclamation from "@assets/icons/solid/calendar-exclamation.svg";
import calendarPlus from "@assets/icons/solid/calendar-plus.svg";
import calendarStar from "@assets/icons/solid/calendar-star.svg";
import calendarXmark from "@assets/icons/solid/calendar-xmark.svg";
import calendar from "@assets/icons/solid/calendar.svg";
import calendars from "@assets/icons/solid/calendars.svg";
import caretDown from "@assets/icons/solid/caret-down.svg";
import caretUp from "@assets/icons/solid/caret-up.svg";
import checkmark from "@assets/icons/solid/check.svg";
import chevronDown from "@assets/icons/solid/chevron-down.svg";
import chevronLeft from "@assets/icons/solid/chevron-left.svg";
import chevronRight from "@assets/icons/solid/chevron-right.svg";
import chevronUp from "@assets/icons/solid/chevron-up.svg";
import child from "@assets/icons/solid/child.svg";
import circleArrowLeft from "@assets/icons/solid/circle-arrow-left.svg";
import circleArrowRight from "@assets/icons/solid/circle-arrow-right.svg";
import check from "@assets/icons/solid/circle-check.svg";
import usdCircle from "@assets/icons/solid/circle-dollar.svg";
import circleExclamation from "@assets/icons/solid/circle-exclamation.svg";
import infoCircle from "@assets/icons/solid/circle-info.svg";
import playCircle from "@assets/icons/solid/circle-play.svg";
import add from "@assets/icons/solid/circle-plus.svg";
import questionCircle from "@assets/icons/solid/circle-question.svg";
import circleStar from "@assets/icons/solid/circle-star.svg";
import videoCircle from "@assets/icons/solid/circle-video.svg";
import times from "@assets/icons/solid/circle-xmark.svg";
import circleSolid from "@assets/icons/solid/circle.svg";
import clipboardListCheck from "@assets/icons/solid/clipboard-list-check.svg";
import clipboardUser from "@assets/icons/solid/clipboard-user.svg";
import history from "@assets/icons/solid/clock-rotate-left.svg";
import clock from "@assets/icons/solid/clock.svg";
import clone from "@assets/icons/solid/clone.svg";
import commentExclamation from "@assets/icons/solid/comment-exclamation.svg";
import commentEdit from "@assets/icons/solid/comment-pen.svg";
import comments from "@assets/icons/solid/comments.svg";
import copy from "@assets/icons/solid/copy.svg";
import crown from "@assets/icons/solid/crown.svg";
import diagramProject from "@assets/icons/solid/diagram-project.svg";
import dollarSign from "@assets/icons/solid/dollar-sign.svg";
import compress from "@assets/icons/solid/down-left-and-up-right-to-center.svg";
import download from "@assets/icons/solid/download.svg";
import ellipsisV from "@assets/icons/solid/ellipsis-vertical.svg";
import ellipsis from "@assets/icons/solid/ellipsis.svg";
import emojiAdd from "@assets/icons/solid/emoji-add.svg";
import envelopeOpen from "@assets/icons/solid/envelope-open.svg";
import envelope from "@assets/icons/solid/envelope.svg";
import eyeSlash from "@assets/icons/solid/eye-slash.svg";
import eye from "@assets/icons/solid/eye.svg";
import faceFrownOpen from "@assets/icons/solid/face-frown-open.svg";
import faceFrown from "@assets/icons/solid/face-frown.svg";
import faceLaugh from "@assets/icons/solid/face-laugh.svg";
import faceMeh from "@assets/icons/solid/face-meh.svg";
import faceSmile from "@assets/icons/solid/face-smile.svg";
import fileDownload from "@assets/icons/solid/file-arrow-down.svg";
import fileUpload from "@assets/icons/solid/file-arrow-up.svg";
import fileChartPie from "@assets/icons/solid/file-chart-pie.svg";
import fileContract from "@assets/icons/solid/file-contract.svg";
import fileLines from "@assets/icons/solid/file-lines.svg";
import file from "@assets/icons/solid/file.svg";
import flag from "@assets/icons/solid/flag.svg";
import folder from "@assets/icons/solid/folder-closed.svg";
import folderOpen from "@assets/icons/solid/folder-open.svg";
import gear from "@assets/icons/solid/gear.svg";
import globe from "@assets/icons/solid/globe.svg";
import handHeart from "@assets/icons/solid/hand-heart.svg";
import handHoldingHeart from "@assets/icons/solid/hand-holding-heart.svg";
import handsHeart from "@assets/icons/solid/hands-holding-heart.svg";
import handshake from "@assets/icons/solid/handshake-simple.svg";
import hashtag from "@assets/icons/solid/hashtag.svg";
import heartCirclePlus from "@assets/icons/solid/heart-circle-plus.svg";
import heart from "@assets/icons/solid/heart.svg";
import hourglass from "@assets/icons/solid/hourglass.svg";
import house from "@assets/icons/solid/house.svg";
import italic from "@assets/icons/solid/italic.svg";
import link from "@assets/icons/solid/link.svg";
import listCheck from "@assets/icons/solid/list-check.svg";
import listOl from "@assets/icons/solid/list-ol.svg";
import listUl from "@assets/icons/solid/list-ul.svg";
import loader from "@assets/icons/solid/loader.svg";
import locationDot from "@assets/icons/solid/location-dot.svg";
import lockOpen from "@assets/icons/solid/lock-open.svg";
import lock from "@assets/icons/solid/lock.svg";
import logoIcon from "@assets/icons/solid/logo-icon.svg";
import search from "@assets/icons/solid/magnifying-glass.svg";
import commentAltPlus from "@assets/icons/solid/message-plus.svg";
import message from "@assets/icons/solid/message.svg";
import messagesQuestion from "@assets/icons/solid/messages-question.svg";
import messages from "@assets/icons/solid/messages.svg";
import microchipAi from "@assets/icons/solid/microchip-ai.svg";
import moneyBill from "@assets/icons/solid/money-bill.svg";
import newspaper from "@assets/icons/solid/newspaper.svg";
import stickyNote from "@assets/icons/solid/note-sticky.svg";
import paperPlaneTop from "@assets/icons/solid/paper-plane-top.svg";
import paperPlane from "@assets/icons/solid/paper-plane.svg";
import paperclip from "@assets/icons/solid/paperclip.svg";
import penField from "@assets/icons/solid/pen-field.svg";
import penToSquare from "@assets/icons/solid/pen-to-square.svg";
import edit from "@assets/icons/solid/pen.svg";
import pencil from "@assets/icons/solid/pencil.svg";
import peopleArrows from "@assets/icons/solid/people-arrows-left-right.svg";
import peopleGroup from "@assets/icons/solid/people-group.svg";
import walking from "@assets/icons/solid/person-walking.svg";
import phone from "@assets/icons/solid/phone.svg";
import photoFilm from "@assets/icons/solid/photo-film.svg";
import plane from "@assets/icons/solid/plane.svg";
import playArrow from "@assets/icons/solid/play-arrow.svg";
import plus from "@assets/icons/solid/plus.svg";
import prescription from "@assets/icons/solid/prescription.svg";
import print from "@assets/icons/solid/print.svg";
import question from "@assets/icons/solid/question.svg";
import quoteLeft from "@assets/icons/solid/quote-left.svg";
import quoteRight from "@assets/icons/solid/quote-right.svg";
import repeat from "@assets/icons/solid/repeat.svg";
import rightToBracket from "@assets/icons/solid/right-to-bracket.svg";
import robot from "@assets/icons/solid/robot.svg";
import balanceScaleRight from "@assets/icons/solid/scale-unbalanced-flip.svg";
import screwdriverWrench from "@assets/icons/solid/screwdriver-wrench.svg";
import sealExclamation from "@assets/icons/solid/seal-exclamation.svg";
import shareSquare from "@assets/icons/solid/share-from-square.svg";
import share from "@assets/icons/solid/share.svg";
import sortDown from "@assets/icons/solid/sort-down.svg";
import sortUp from "@assets/icons/solid/sort-up.svg";
import sort from "@assets/icons/solid/sort.svg";
import spa from "@assets/icons/solid/spa.svg";
import spinner from "@assets/icons/solid/spinner.svg";
import arrowSquareRight from "@assets/icons/solid/square-arrow-right.svg";
import arrowUpRightSquare from "@assets/icons/solid/square-arrow-up-right.svg";
import arrowUpSquare from "@assets/icons/solid/square-arrow-up.svg";
import squareCheck from "@assets/icons/solid/square-check.svg";
import addSquare from "@assets/icons/solid/square-plus.svg";
import star from "@assets/icons/solid/star.svg";
import stethoscope from "@assets/icons/solid/stethoscope.svg";
import store from "@assets/icons/solid/store.svg";
import columns from "@assets/icons/solid/table-columns.svg";
import tableLayout from "@assets/icons/solid/table-layout.svg";
import tags from "@assets/icons/solid/tags.svg";
import thumbsDown from "@assets/icons/solid/thumbs-down.svg";
import thumbsUp from "@assets/icons/solid/thumbs-up.svg";
import thumbtack from "@assets/icons/solid/thumbtack.svg";
import ticket from "@assets/icons/solid/ticket.svg";
import toolbox from "@assets/icons/solid/toolbox.svg";
import trash from "@assets/icons/solid/trash.svg";
import treePalm from "@assets/icons/solid/tree-palm.svg";
import exclamationTriangle from "@assets/icons/solid/triangle-exclamation.svg";
import underline from "@assets/icons/solid/underline.svg";
import expand from "@assets/icons/solid/up-right-and-down-left-from-center.svg";
import upload from "@assets/icons/solid/upload.svg";
import userClock from "@assets/icons/solid/user-clock.svg";
import userDoctor from "@assets/icons/solid/user-doctor.svg";
import usersCrown from "@assets/icons/solid/user-group-crown.svg";
import userSlash from "@assets/icons/solid/user-slash.svg";
import userTimes from "@assets/icons/solid/user-xmark.svg";
import user from "@assets/icons/solid/user.svg";
import users from "@assets/icons/solid/users.svg";
import videoPlus from "@assets/icons/solid/video-plus.svg";
import video from "@assets/icons/solid/video.svg";
import wandMagic from "@assets/icons/solid/wand-magic.svg";
import wrench from "@assets/icons/solid/wrench.svg";
import xmark from "@assets/icons/solid/xmark.svg";

export const IconList = {
  add,
  addSquare,
  addressCard,
  alarmClock,
  apple,
  arrowLeft,
  arrowRight,
  arrowRightFromBracket,
  arrowSquareRight,
  arrowUpRightSquare,
  arrowUpSquare,
  at,
  badgeCheck,
  balanceScaleRight,
  barFilters,
  barFiltersDuo,
  barFiltersNone,
  basketShopping,
  bell,
  bold,
  book,
  bookCopy,
  bookHeart,
  bookOpen,
  bookmark,
  boxArchive,
  bridgeSuspension,
  briefcaseMedical,
  building,
  bullseyeArrow,
  calendar,
  calendarCheck,
  calendarClock,
  calendarDay,
  calendarDays,
  calendarExclamation,
  calendarPlus,
  calendarStar,
  calendarXmark,
  calendars,
  caretDown,
  caretUp,
  check,
  checkmark,
  chevronDown,
  chevronLeft,
  chevronRight,
  chevronUp,
  child,
  circle,
  circleArrowLeft,
  circleArrowRight,
  circleDot,
  circleExclamation,
  circleSolid,
  circleStar,
  clipboardListCheck,
  clipboardUser,
  clock,
  clone,
  columns,
  commentAltPlus,
  commentEdit,
  commentExclamation,
  comments,
  complete: checkmark,
  compress,
  copy,
  crown,
  diagramProject,
  dollarSign,
  download,
  edit,
  ellipsis,
  ellipsisV,
  emojiAdd,
  envelope,
  envelopeOpen,
  exclamationTriangle,
  expand,
  eye,
  eyeSlash,
  faceFrown,
  faceFrownOpen,
  faceLaugh,
  faceMeh,
  faceSmile,
  facebookF,
  file,
  fileChartPie,
  fileContract,
  fileDownload,
  fileLines,
  fileUpload,
  flag,
  folder,
  folderOpen,
  gear,
  general,
  generalCircle,
  globe,
  handHeart,
  handHoldingHeart,
  handsHeart,
  handshake,
  hashtag,
  heart,
  heartCirclePlus,
  heartOutline,
  history,
  hourglass,
  house,
  infoCircle,
  italic,
  link,
  linkedinIn,
  listCheck,
  listOl,
  listUl,
  loader,
  locationDot,
  lock,
  lockOpen,
  logoIcon,
  message,
  messages,
  messagesQuestion,
  microchipAi,
  moneyBill,
  newspaper,
  paperPlane,
  paperPlaneTop,
  paperclip,
  penField,
  penToSquare,
  pencil,
  peopleArrows,
  peopleGroup,
  phone,
  photoFilm,
  plane,
  playArrow,
  playCircle,
  plus,
  prescription,
  print,
  question,
  questionCircle,
  quoteLeft,
  quoteRight,
  repeat,
  rightToBracket,
  robot,
  screwdriverWrench,
  sealExclamation,
  search,
  share,
  shareSquare,
  sort,
  sortDown,
  sortUp,
  spa,
  spinner,
  squareCheck,
  star,
  stethoscope,
  stickyNote,
  store,
  tableLayout,
  tags,
  thumbsDown,
  thumbsUp,
  thumbtack,
  thumbtackOutline,
  ticket,
  times,
  toolbox,
  trash,
  treePalm,
  twitter,
  underline,
  upload,
  usdCircle,
  user,
  userClock,
  userDoctor,
  userSlash,
  userTimes,
  users,
  usersCrown,
  video,
  videoCircle,
  videoPlus,
  walking,
  wandMagic,
  wrench,
  xmark
};

export type IconName = keyof typeof IconList;
