import { IntlShape } from "react-intl";

export const truncate = (value: string | null, length: number) => {
  if (!value) {
    return "";
  }
  if (value.length <= length) {
    return value;
  }
  return value.slice(0, length) + "...";
};

export const capitalizeFirstLetter = (input: string) => input.charAt(0).toUpperCase() + input.slice(1);

export const stripText = (value: string) => value.replace(/<\/?[^>]+(>|$)/g, "");

export const isValidEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email) && !email.includes(" ");
};

export const isValidPhoneNumber = (phoneNumber: string) => {
  const re = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
  return re.test(phoneNumber);
};

export const isValidHttpUrl = (string: string) => {
  try {
    const url = new URL(string);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch {
    return false;
  }
};

export const sortStrings = (list: string[]) =>
  list.sort((a, b) => {
    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  });

export const replaceAll = (string: string, find: string, replace: string) =>
  string.replace(new RegExp(find, "g"), replace);

export const joinListWithCommasAndAnd = (items: string[], intl: IntlShape, conjunction = "dictionary.and"): string => {
  let result = "";
  if (items.length == 1) {
    return items[0];
  } else if (items.length == 2) {
    return `${items[0]} ${intl.formatMessage({ id: conjunction })} ${items[1]}`;
  } else {
    items.forEach((item, index) => {
      result = result + item;
      if (index < items.length - 2) {
        result += intl.formatMessage({ id: "dictionary.punctuation.comma" }) + " ";
      } else if (index === items.length - 2) {
        result +=
          intl.formatMessage({ id: "dictionary.punctuation.comma" }) +
          " " +
          intl.formatMessage({ id: conjunction }) +
          " ";
      }
    });
  }

  return result;
};

export const shortenWebsite = (url: string) =>
  url.match(/^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/)?.[0] ?? "";

export const snakeToCamel = (str: string | null) =>
  str?.toLowerCase().replace(/([-_][a-z])/g, group => group.toUpperCase().replace("-", "").replace("_", ""));

export const translateBool = (value: boolean | null, intl: IntlShape) =>
  value ? intl.formatMessage({ id: "dictionary.yes" }) : intl.formatMessage({ id: "dictionary.no" });

export const escapeRegularExpressionText = (text: string) => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, String.raw`\$&`);
