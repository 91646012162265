import { FieldValues, Path, SetValueConfig, UseFormReturn } from "react-hook-form";
import { IntlShape } from "react-intl";
import { StylesConfig } from "react-select";

import { employerSelectSelfPaid } from "@typing/Constants";
import { lightenColor } from "@utils/colorUtils";

type Error = {
  errors: string[];
  field: string;
};

export type ThingWithErrors = {
  errors: Error[];
};

export type ErrorSetter<FormType> = (field: Path<FormType>, options: { message: string }) => void;

export type HandleMutationArgs<FormType> = {
  data: ThingWithErrors | ThingWithErrors[] | null | undefined;
  extraBaseFields?: string[];
  intl: IntlShape;
  onError?: () => void;
  onSuccess?: () => void;
  setBaseErrors: (errors: string[]) => void;
  setError: ErrorSetter<FormType>;
  txBase: string;
};

export const handleMutationResponse = <FormType>(args: HandleMutationArgs<FormType>) => {
  const { data, intl, onError, onSuccess, setBaseErrors, setError, txBase } = args;

  let useData: ThingWithErrors[] | null | undefined;

  if (Array.isArray(data)) {
    useData = data;
  } else if (data) {
    useData = [data];
  }

  let noErrors = true;
  useData?.forEach(data => {
    if (data?.errors && data?.errors?.length > 0) {
      noErrors = false;
      data.errors.forEach(fieldError => {
        if (fieldError.field === "base" || args.extraBaseFields?.includes(fieldError.field)) {
          const messages = fieldError.errors.map(error => intl.formatMessage({ id: `${txBase}.base.${error}` }));

          setBaseErrors(messages);
        } else {
          const messages = fieldError.errors.map(error =>
            intl.formatMessage({ id: `${txBase}.${fieldError.field}.${error}` })
          );

          setError(fieldError.field as Path<FormType>, { message: messages.join(" ") });
        }
      });
    }
  });
  if (noErrors) {
    onSuccess?.();
  } else {
    onError?.();
  }
};

export const filterForDirtyValidValues = <FormValues extends FieldValues>(form: UseFormReturn<FormValues>) => {
  const dirtyValidValues: Partial<FormValues> = {};
  const values = form.getValues();

  for (const fieldName in values) {
    const fieldState = form.getFieldState(fieldName as unknown as Path<FormValues>);
    if (!fieldState.invalid && fieldState.isDirty) {
      dirtyValidValues[fieldName] = values[fieldName];
    }
  }

  return dirtyValidValues;
};

export const searchSelectStyles = (color: "white" | "neutral") => ({
  container: (base: any, _state: any) => ({
    ...base,
    textAlign: "left"
  }),
  control: (base: any, state: any) => ({
    ...base,
    backgroundColor: color === "white" ? "var(--ion-color-white)" : "var(--ion-color-neutral)",
    borderRadius: 28,
    borderStyle: "solid",
    borderWidth: 0,
    boxShadow: "none",
    color: "var(--ion-color-gray)",
    outline: state.isFocused ? "2px solid var(--ion-color-primary-300)" : "none",
    overflow: "visible",
    paddingBottom: "10.25px",
    paddingLeft: "44px",
    paddingTop: "10.25px",
    zIndex: state.isFocused ? 50 : undefined
  }),
  groupHeading: (base: any) => ({
    ...base,
    padding: 0
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    height: 0
  }),
  input: (base: any) => ({
    ...base,
    color: "var(--ion-color-gray)"
  }),
  menu: (base: any) => ({
    ...base,
    backgroundColor: color === "white" ? "var(--ion-color-white)" : "var(--ion-color-neutral)",
    borderRadius: 24,
    boxShadow:
      "0px 6px 13px 0px rgba(78, 69, 146, 0.06), 0px 23px 23px 0px rgba(78, 69, 146, 0.06), 0px 53px 32px 0px rgba(78, 69, 146, 0.03), 0px 94px 37px 0px rgba(78, 69, 146, 0.01), 0px 146px 41px 0px rgba(78, 69, 146, 0.00)",
    marginTop: "12px",
    padding: 0
  }),
  menuPortal: (base: any) => ({
    ...base,
    borderRadius: 12,
    maxWidth: undefined,
    zIndex: 9999
  }),
  multiValue: (base: any, { data }: any) => {
    if (data.color) {
      return {
        ...base,
        backgroundColor: data.color
      };
    }
    return base;
  },
  multiValueLabel: (base: any, { data }: any) => {
    if (data.color) {
      return {
        ...base,
        color: "var(--ion-color-white)"
      };
    }
    return base;
  },
  multiValueRemove: (base: any, { data }: any) => {
    if (data.color) {
      return {
        ...base,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ":hover": {
          backgroundColor: lightenColor(data.color, 10),
          color: "white"
        },
        backgroundColor: lightenColor(data.color, 5),
        color: "white"
      };
    }
    return base;
  },
  option: (base: any, { data, isActive, isDisabled, isFocused, isSelected }: any) => {
    if (data.color) {
      return {
        ...base,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ":active": {
          ...base[":active"],
          backgroundColor: !isDisabled ? (isSelected ? data.color : lightenColor(data.color, 60)) : undefined
        },
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? lightenColor(data.color, 55)
              : undefined,
        borderRadius: 100,
        color: isDisabled ? "#ccc" : isSelected ? "white" : data.color,
        cursor: isDisabled ? "not-allowed" : "default",
        paddingLeft: 24
      };
    }
    return {
      ...base,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ":active": {
        ...base[":active"],
        backgroundColor: "var(--ion-color-primary-50)"
      },
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? `var(--ion-color-primary-50)`
          : isActive
            ? "var(--ion-color-primary-100)"
            : isFocused
              ? "var(--ion-color-primary-100)"
              : undefined,
      borderRadius: 0,
      color: isDisabled
        ? "var(--ion-color-gray-300-shade)"
        : isSelected
          ? "var(--ion-color-gray)"
          : "var(--ion-color-gray)",
      cursor: "pointer",
      fontWeight: "bold",
      paddingLeft: 24,
      paddingTop: 15
    };
  },
  placeholder: (base: any) => ({
    ...base,
    color: color === "neutral" ? "var(--ion-color-gray-300)" : "var(--ion-color-gray-300)"
  }),
  valueContainer: (base: any) => ({
    ...base,
    paddingBottom: 0,
    paddingTop: 0
  })
});

export const selectStyles = (size: "normal" | "large" = "normal") => ({
  container: (base: any, _state: any) => ({
    ...base,
    textAlign: "left"
  }),
  control: (base: any, state: any) => {
    const controlStyles = {
      ...base,
      backgroundColor: "var(--ion-color-white)",
      borderColor: "var(--ion-color-gray-200)",
      borderRadius: "5px",
      borderStyle: "solid",
      borderWidth: 1,
      outline: state.isFocused ? "2px solid var(--ion-color-primary-300)" : "none"
    };
    if (size === "normal") {
      controlStyles.minHeight = "32px";
      controlStyles.paddingBottom = "0px";
      controlStyles.paddingTop = "0px";
    } else if (size === "large") {
      controlStyles.paddingBottom = "8px";
      controlStyles.paddingTop = "8px";
    }
    return controlStyles;
  },
  dropdownIndicator: (base: any, _state: any) => {
    const dropdownIndicatorStyles = { ...base };
    if (size === "normal") {
      dropdownIndicatorStyles.padding = "0 8px";
    }
    return dropdownIndicatorStyles;
  },
  indicatorsContainer: (base: any, _state: any) => {
    const indicatorsContainerStyles = { ...base };
    if (size === "normal") {
      indicatorsContainerStyles.padding = "0 8px";
    }
    return indicatorsContainerStyles;
  },
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  multiValue: (base: any, { data }: any) => {
    if (data.color) {
      return {
        ...base,
        backgroundColor: data.color
      };
    }
    return base;
  },
  multiValueLabel: (base: any, { data }: any) => {
    if (data.color) {
      return {
        ...base,
        color: "var(--ion-color-white)"
      };
    }
    return base;
  },
  multiValueRemove: (base: any, { data }: any) => {
    if (data.color) {
      return {
        ...base,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ":hover": {
          backgroundColor: lightenColor(data.color, 10),
          color: "white"
        },
        backgroundColor: lightenColor(data.color, 5),
        color: "white"
      };
    }
    return base;
  },
  option: (base: any, { data, isDisabled, isFocused, isSelected }: any) => {
    if (data.color) {
      return {
        ...base,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ":active": {
          ...base[":active"],
          backgroundColor: !isDisabled ? (isSelected ? data.color : lightenColor(data.color, 60)) : undefined
        },
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? lightenColor(data.color, 55)
              : undefined,
        color: isDisabled ? "#ccc" : isSelected ? "white" : data.color,
        cursor: isDisabled ? "not-allowed" : "default"
      };
    }
    return {
      ...base,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? `var(--ion-color-primary-500)`
          : isFocused
            ? "var(--ion-color-primary-50-tint)"
            : undefined,
      color: isDisabled
        ? "var(--ion-color-gray-300-shade)"
        : isSelected
          ? "var(--ion-color-white)"
          : "var(--ion-color-primary-500)"
    };
  }
});

export const selectStylesV2 = () => ({
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
});

export const floatingSelectStyles = () => ({
  container: (base: any, _state: any) => ({
    ...base,
    textAlign: "left"
  }),
  control: (base: any, state: any) => {
    const controlStyles = {
      ...base,
      backgroundColor: "transparent",
      border: 0,
      borderBottom: state.isFocused
        ? "2px solid var(--ion-color-primary-300) !important"
        : "1px solid var(--ion-color-gray-300-shade)",
      borderRadius: 0,
      boxShadow: "none",
      outline: state.isFocused ? "0px solid var(--ion-color-primary-300)" : "none"
    };
    controlStyles.minHeight = "32px";
    controlStyles.height = "33.5px";
    controlStyles.paddingBottom = "0px";
    controlStyles.paddingTop = "0px";

    return controlStyles;
  },
  dropdownIndicator: (base: any, _state: any) => {
    const dropdownIndicatorStyles = { ...base };
    dropdownIndicatorStyles.padding = "0 8px";

    return dropdownIndicatorStyles;
  },
  indicatorsContainer: (base: any, _state: any) => {
    const indicatorsContainerStyles = { ...base };
    indicatorsContainerStyles.padding = "0 8px";

    return indicatorsContainerStyles;
  },
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  multiValue: (base: any, { data }: any) => {
    if (data.color) {
      return {
        ...base,
        backgroundColor: data.color
      };
    }
    return base;
  },
  multiValueLabel: (base: any, { data }: any) => {
    if (data.color) {
      return {
        ...base,
        color: "var(--ion-color-white)"
      };
    }
    return base;
  },
  multiValueRemove: (base: any, { data }: any) => {
    if (data.color) {
      return {
        ...base,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ":hover": {
          backgroundColor: lightenColor(data.color, 10),
          color: "white"
        },
        backgroundColor: lightenColor(data.color, 5),
        color: "white"
      };
    }
    return base;
  },
  option: (base: any, { data, isDisabled, isFocused, isSelected }: any) => {
    if (data.color) {
      return {
        ...base,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ":active": {
          ...base[":active"],
          backgroundColor: !isDisabled ? (isSelected ? data.color : lightenColor(data.color, 60)) : undefined
        },
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? lightenColor(data.color, 55)
              : undefined,
        color: isDisabled ? "#ccc" : isSelected ? "white" : data.color,
        cursor: isDisabled ? "not-allowed" : "default"
      };
    }
    return {
      ...base,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? `var(--ion-color-primary-500)`
          : isFocused
            ? "var(--ion-color-primary-50-tint)"
            : undefined,
      color: isDisabled
        ? "var(--ion-color-gray-300-shade)"
        : isSelected
          ? "var(--ion-color-white)"
          : "var(--ion-color-primary-500)"
    };
  }
});

export const editorSelectStyles = {
  ...selectStyles,
  clearIndicator: (base: any) => ({
    ...base,
    paddingBottom: "0px",
    paddingTop: "0px"
  }),
  control: (base: any, state: any) => ({
    ...base,
    backgroundColor: "var(--ion-color-white)",
    borderColor: "var(--ion-color-gray-200)",
    borderRadius: "5px",
    borderStyle: "solid",
    borderWidth: 1,
    fontSize: "14px !important",
    height: "28px",
    marginBottom: "4px",
    marginLeft: "4px",
    marginRight: "4px",
    marginTop: "4px",
    minHeight: "28px",
    outline: state.isFocused ? "2px solid var(--ion-color-primary-300)" : "none",
    paddingBottom: "0px",
    paddingTop: "0px"
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    paddingBottom: "0px",
    paddingTop: "0px"
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    padding: "0 8px"
  }),
  input: (base: any) => ({
    ...base,
    margin: "0px 2px",
    padding: "0px"
  }),
  placeholder: (base: any) => ({
    ...base,
    whiteSpace: "noWrap"
  })
};

type ThingWithEmployerIdAndPaid = {
  employerId?: string | null;
  selfPaid?: boolean | null;
};

export function onChangeWithPaid<Model extends ThingWithEmployerIdAndPaid>(
  onChange: (values: Model) => void,
  values: Model
) {
  if (values.employerId === employerSelectSelfPaid) {
    onChange({ ...values, employerId: "", selfPaid: true });
  } else {
    onChange({ ...values, selfPaid: false });
  }
}

export const inputSelectStyles = <T>(): StylesConfig<T> => ({
  clearIndicator: base => ({
    ...base,
    display: "none"
  }),
  container: base => ({
    ...base,
    textAlign: "left"
  }),
  control: (base, { isFocused }) => ({
    ...base,
    backgroundColor: "var(--ion-color-primary-50)",
    border: isFocused ? "2px solid var(--ion-color-primary-300) !important" : "2px solid transparent !important",
    borderRadius: "5px",
    cursor: "pointer",
    paddingBottom: "4px",
    paddingTop: "4px"
  }),
  dropdownIndicator: base => ({
    ...base,
    paddingBottom: "0px",
    paddingTop: "0px"
  }),
  indicatorSeparator: base => ({
    ...base,
    display: "none"
  }),
  indicatorsContainer: base => ({
    ...base
    // display: "none"
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  multiValue: base => ({
    ...base,
    alignItems: "center",
    background: "var(--ion-color-primary-300)",
    border: "4px solid var(--ion-color-primary-300)",
    borderRadius: "100px",
    display: "flex",
    padding: "0"
  }),
  multiValueLabel: base => ({
    ...base,
    color: "var(--ion-color-white)",
    padding: "0",
    paddingLeft: "0",
    paddingRight: "2px"
  }),
  multiValueRemove: base => ({ ...base, display: "none" }),
  option: (base, { isDisabled, isFocused, isSelected }) => ({
    ...base,
    backgroundColor: isDisabled
      ? undefined
      : isSelected
        ? `var(--ion-color-primary-500)`
        : isFocused
          ? "var(--ion-color-primary-50-tint)"
          : undefined,
    color: isDisabled
      ? "var(--ion-color-gray-300-shade)"
      : isSelected
        ? "var(--ion-color-white)"
        : "var(--ion-color-primary-500)",
    cursor: "pointer"
  })
});

export const scheduleSelectStyles = {
  ...inputSelectStyles,
  control: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isDisabled ? "var(--ion-color-gray-25)" : "var(--ion-color-white)",
    borderColor: "var(--ion-color-gray-200)",
    borderRadius: "5px",
    borderStyle: "solid",
    borderWidth: 1,
    outline: state.isFocused ? "2px solid var(--ion-color-primary-300)" : "none",
    paddingBottom: "8px",
    paddingTop: "8px"
  })
};

export type RichTextareaMenuControls =
  | "attachments"
  | "bold"
  | "bulletList"
  | "headings"
  | "inAppLinks"
  | "internalNoteTemplates"
  | "italic"
  | "link"
  | "mergeTags"
  | "orderedList"
  | "submit"
  | "templates"
  | "underline";

export const standardRichTextareaMenuControls: RichTextareaMenuControls[] = [
  "bold",
  "bulletList",
  "headings",
  "italic",
  "link",
  "orderedList",
  "underline"
];

export const extraRichTextareaMenuControls: RichTextareaMenuControls[] = [
  "attachments",
  "internalNoteTemplates",
  "inAppLinks",
  "mergeTags",
  "templates"
];

export const triggerFormEffects: SetValueConfig = {
  shouldDirty: true,
  shouldTouch: true,
  shouldValidate: true
};

export const generateRegisterEvent = ({ name, value }: { name: string; value: string | null | undefined }) => ({
  target: {
    name,
    value
  },
  type: "change"
});
