import { FormattedDate, FormattedMessage } from "react-intl";

import styles from "@components/v1/dates/Dates.module.css";
import Nbsp from "@components/v1/Nbsp";
import useGetMyTimeWithTimezoneAbbreviation from "@hooks/useGetMyTimeWithTimezoneAbbreviation";

type Props = {
  mobileTimeOnNewLine?: boolean;
  showTimezone?: boolean;
  showWeekday?: boolean;
  showYear?: boolean;
  timeOnNewLine?: boolean;
  timezone?: string;
  value: Date;
  weekdayLong?: boolean;
};

const DateAndTimeStamp = ({
  mobileTimeOnNewLine = false,
  showTimezone = true,
  showWeekday = true,
  showYear = true,
  timeOnNewLine,
  timezone,
  value,
  weekdayLong = false
}: Props) => {
  const getMyTimeWithTimezoneAbbreviation = useGetMyTimeWithTimezoneAbbreviation(showTimezone);

  return (
    <>
      <FormattedDate
        day="numeric"
        month="short"
        timeZone={timezone}
        value={value}
        weekday={weekdayLong ? "long" : showWeekday ? "short" : undefined}
      />
      {showYear && (
        <>
          <FormattedMessage id="dictionary.punctuation.comma" />
          <Nbsp />
          <FormattedDate timeZone={timezone} value={value} year="numeric" />
        </>
      )}

      <Nbsp />
      <div className={timeOnNewLine ? styles.newLine : mobileTimeOnNewLine ? styles.newLineMobile : styles.inline}>
        {getMyTimeWithTimezoneAbbreviation(value)}
      </div>
    </>
  );
};

export default DateAndTimeStamp;
