import SparkMD5 from "spark-md5";

import { FileResourceFragment } from "@typing/Generated";

export const getFileImage = (fileResource: FileResourceFragment) => {
  const contentType = fileResource.attachment?.contentType;

  if (contentType === "image/jpeg") {
    return "jpg";
  } else if (contentType?.includes("image/")) {
    return "image";
  } else if (contentType === "application/pdf" || fileResource.pdfType) {
    return "pdf";
  } else if (
    contentType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
    contentType === "application/msword"
  ) {
    return "docx";
  }

  return "fallback";
};

export const isPdf = (fileResource: FileResourceFragment) => {
  const contentType = fileResource.attachment?.contentType;
  return contentType === "application/pdf";
};

export const isImage = (fileResource: FileResourceFragment) => {
  const contentType = fileResource.attachment?.contentType;
  return contentType?.includes("image/");
};

export const computeChecksumMd5 = async (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const blobSlice = File.prototype.slice; // || File.prototype.mozSlice || File.prototype.webkitSlice;
    const chunkSize = 2097152;
    const chunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();

    function loadNext() {
      const start = currentChunk * chunkSize;
      const end = start + chunkSize >= file.size ? file.size : start + chunkSize;

      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    }

    fileReader.onload = function (e) {
      spark.append(e?.target?.result as ArrayBuffer);
      currentChunk++;

      if (currentChunk < chunks) {
        loadNext();
      } else {
        resolve(btoa(spark.end(true)));
      }
    };

    fileReader.onerror = function () {
      reject("MD5 computation failed - error reading the file");
    };

    loadNext();
  });

type DirectUploadArgs = {
  file: File;
  jsonEncodedHeaders: string;
  onProgress?: (percentComplete: number) => void;
  url: string;
};

export const directUpload = async ({ file, jsonEncodedHeaders, onProgress, url }: DirectUploadArgs) => {
  const headers = JSON.parse(jsonEncodedHeaders) as Record<string, string>;

  const xhr = new XMLHttpRequest();
  xhr.open("PUT", url, true);
  xhr.responseType = "text";

  for (const key in headers) {
    xhr.setRequestHeader(key, headers[key]);
  }

  const handleProgress = (event: ProgressEvent<XMLHttpRequestEventTarget>) => {
    try {
      if (event.lengthComputable) {
        const percent = (event.loaded / event.total) * 75.0;
        onProgress?.(percent);
      }
    } finally {
      xhr.upload.removeEventListener("progress", handleProgress);
    }
  };

  xhr.upload.addEventListener("progress", handleProgress);

  return new Promise((resolve, reject) => {
    const handleLoad = () => {
      try {
        const { response, status } = xhr;
        if (status >= 200 && status < 300) {
          onProgress?.(100.0);
          resolve(response);
        } else {
          reject(`Error storing "${file.name}". Status: ${xhr.status}`);
        }
      } finally {
        xhr.removeEventListener("load", handleLoad);
      }
    };
    xhr.addEventListener("load", handleLoad);

    const handleError = () => {
      try {
        reject(`Error storing "${file.name}". Status: ${xhr.status}`);
      } finally {
        xhr.removeEventListener("error", handleError);
      }
    };
    xhr.addEventListener("error", handleError);

    xhr.send(file.slice());
  });
};
