/* eslint-disable @typescript-eslint/naming-convention */
const imageNameMap = {
  activateFeatureDogWash: "activateFeatures/dogWash.png",
  activateFeatureFamily: "activateFeatures/family.png",
  activateFeatureHipaa: "activateFeatures/hipaa.png",
  activateFeatureStarRating: "activateFeatures/starrating.png",
  activateLogoEntrepeneur: "activateLogos/entrepeneur.png",
  activateLogoForbes: "activateLogos/forbes.png",
  activateLogoTime: "activateLogos/time.png",
  activateLogoUsnews: "activateLogos/usnews.png",
  activateLogoWomensHealth: "activateLogos/womenshealth.png",
  activateTestimonialAdult: "activateTestimonials/adult.png",
  activateTestimonialAging: "activateTestimonials/aging.png",
  activateTestimonialYourself: "activateTestimonials/yourself.png",
  activateTopicAdult: "activateTopics/adult.png",
  activateTopicAging: "activateTopics/aging.png",
  activateTopicChild: "activateTopics/child.png",
  activateTopicGrieving: "activateTopics/grieving.png",
  activateTopicPageFeatureEasyAccess: "activateTopicPage/easyAccess.png",
  activateTopicPageFeatureExpertSupport: "activateTopicPage/expertSupport.png",
  activateTopicPageFeatureSaveTime: "activateTopicPage/saveTime.png",
  activateTopicPageFocusAreaFinancial: "activateTopicPage/financial.png",
  activateTopicPageFocusAreaFunctional: "activateTopicPage/functional.png",
  activateTopicPageFocusAreaLegal: "activateTopicPage/legal.png",
  activateTopicPageFocusAreaMedical: "activateTopicPage/medical.png",
  activateTopicPageFocusAreaSocial: "activateTopicPage/social.png",
  activateTopicPageFocusAreaWellbeing: "activateTopicPage/wellbeing.png",
  activateTopicPageTopAdult: "activateTopicPage/adult.png",
  activateTopicPageTopAging: "activateTopicPage/aging.png",
  activateTopicPageTopChild: "activateTopicPage/child.png",
  activateTopicPageTopGrieving: "activateTopicPage/grieving.png",
  activateTopicPageTopPregnancy: "activateTopicPage/pregnancy.png",
  activateTopicPregnancy: "activateTopics/pregnancy.png",
  activeElders: "active-elders.svg",
  activities: "activities.svg",
  appleAppStoreBadge: "apple-app-store-badge.svg",
  auntOrUncle: "aunt-or-uncle.svg",
  avatar: "avatar.svg",
  bench: "bench.svg",
  bereavement: "care_plan_bereavement.svg",
  calendar: "calendar.svg",
  chairCoffeeGuy: "chair-coffee-guy.svg",
  checklist: "checklist.svg",
  checklistGiantPencil: "checklist-giant-pencil.svg",
  chess: "chess.svg",
  clientStatuses: "client_statuses.png",
  clipBoard: "clip-board.svg",
  coffeeNoLamp: "coffee-no-lamp.svg",
  community: "community.png",
  communityFamily: "community-family.png",
  communityShareStory: "community-share-story.svg",
  computerHeart: "computer-heart.svg",
  contentLock: "content-lock.svg",
  crisis: "care_plan_crisis.svg",
  dancing: "dancing.svg",
  dancingSeniors: "dancing-seniors.svg",
  dependent: "care_plan_dependent.svg",
  docx: "docx.svg",
  domesticPartner: "domestic-partner.svg",
  earth: "earth.svg",
  employerList: "employer-list.svg",
  endOfLife: "care_plan_end_of_life.svg",
  end_of_life: "care_plan_end_of_life.svg",
  envelopeHeart: "envelope-heart.svg",
  events: "events.png",
  fallback: "fallback.svg",
  family: "family.svg",
  femaleDoctorAtComputer: "female-doctor-at-computer.svg",
  file: "file-image.svg",
  financial: "financial.svg",
  fishingInBoat: "fishing-in-boat.svg",
  fiveStars: "5-stars.png",
  fosterParent: "foster-parent.svg",
  friendOfParent: "friend-of-parent.svg",
  functional: "care-home.svg",
  gather: "gather.png",
  gatherpalooza: "gatherpalooza.png",
  givingFlowers: "giving-flowers.svg",
  givingGift: "giving-gift.svg",
  googleLogo: "google-logo.svg",
  googlePlayStoreBadge: "google-play-store-badge.png",
  grayceEventCalendar: "grayce-event-calendar.svg",
  happyFamily: "happy-family.svg",
  happyFamilyBed: "happy-family.png",
  happyWomenJumping: "happy-women-jumping.svg",
  heroMontage: "hero-montage.svg",
  huggingHeart: "hugging-heart.svg",
  image: "image.svg",
  independent: "care_plan_independent.svg",
  interdependent: "care_plan_interdependent.svg",
  isolationMode: "isolation-mode.svg",
  jpg: "jpg.svg",
  ladyWritingMessage: "lady-writing-message.svg",
  legal: "legal.svg",
  logoIcon: "logo-icon.svg",
  logoPureWhite: "logo-pure-white.png",
  logoPurple: "logo-purple.svg",
  logoVertical: "logo-vertical.svg",
  logoWhite: "logo-white.svg",
  logoWhiteStacked: "logo-white-stacked.svg",
  mail: "mail.svg",
  manAtDesk: "man-at-desk.svg",
  manCarryingFiles: "man-carrying-files.svg",
  manHoldingLargePhone: "man-holding-large-phone.svg",
  manReadingRadio: "man-reading-radio.svg",
  manReadingTablet: "man-reading-tablet.svg",
  manSittingOnBookPile: "man-sitting-on-book-pile.svg",
  manSittingSmiles: "man-sitting-smiles.svg",
  manSittingWithLaptop: "man-sitting-with-laptop.svg",
  manWithGiantMagnifyingGlass: "man-with-giant-magnifying-glass.svg",
  manWithScrewdriver: "man-with-screwdriver.svg",
  manWithSenior: "man-with-senior.svg",
  manWomanChildWalking: "man-woman-child-walking.svg",
  managerGroup: "manager-group.svg",
  map: "map.svg",
  mapBusSelfie: "map-bus-selfie.svg",
  mapPin: "map-pin.svg",
  marketplace: "marketplace.png",
  media: "articles.png",
  medical: "medical.svg",
  medicalComplete: "medical-complete.svg",
  medicalIncomplete: "medical-incomplete.svg",
  medicationGroup: "medication-group.svg",
  momDaughterMaze: "mom-daughter-maze.svg",
  momDaughterMazeMobile: "mom-daughter-maze-mobile.svg",
  newClients: "new-client.svg",
  pdf: "pdf.svg",
  peopleCoffeeLight: "people-coffee-light.svg",
  peopleDancing: "people-dancing.svg",
  peopleHoldingHearts: "people-holding-hearts.svg",
  personComputer: "person-computer.svg",
  planChecklist: "plan-checklist.svg",
  poweredByGoogle: "powered_by_google_on_white.png",
  providersComplete: "providers-complete.svg",
  resources: "resources.svg",
  safetyReport: "safety-report.svg",
  sharedWorksheet: "shared-worksheet.png",
  sheet: "sheet.svg",
  shoppingCart: "shopping-cart.svg",
  shoppingCartNoBg: "shopping-cart-no-bg.svg",
  sixtyMinutes: "60-minutes.svg",
  sixtyMinutesTeal: "60-minutes-teal.svg",
  smilingPeople: "smiling-people.png",
  social: "social-connection.svg",
  stripOfActivities: "strip-of-activities.svg",
  thirtyMinutes: "30-minutes.svg",
  thirtyMinutesTeal: "30-minutes-teal.svg",
  toolLibrary: "tool-library.svg",
  twoPeopleCoffee: "two-people-drinking-coffee.svg",
  under_eighteen: "man-woman-child-walking.svg",
  washHands: "washing-hands.png",
  wellbeing: "well-being.svg",
  womanAlarmClock: "woman-alarm-clock.svg",
  womanAtDesk: "woman-at-desk.svg",
  womanChairComputer: "woman-chair-computer.svg",
  womanPlan: "woman-plan.svg",
  womanShoppingPresent: "woman-shopping-present.svg",
  womanSittingOnGiantMugNoSteam: "woman-sitting-on-giant-mug-no-steam.svg",
  womanWithCoffee: "woman-with-coffee.svg",
  workingOnIt: "working-on-it.svg",
  yourCareGroup: "your-care-group.svg",
  zoom: "zoom-icon.png"
};

export type ImageName = keyof typeof imageNameMap;

export const imageUrl = (name: ImageName) => new URL(`../assets/images/${imageNameMap[name]}`, import.meta.url).href;

const textureNameMap = {
  blueSplash: "blue-splash.png",
  peachSplash: "peach-splash.png",
  pinkSplash: "pink-splash.png",
  purpleSplash: "purple-splash.png",
  tealSplash: "teal-splash.png"
};

export type TextureName = keyof typeof textureNameMap;

export const textureUrl = (name: TextureName) =>
  new URL(`../assets/images/textures/${textureNameMap[name]}`, import.meta.url).href;

const communityNameMap = {
  groupAskAnExpertImage: "group-ask-an-expert-image.png",
  groupGeneralImage: "group-general-image.png",
  profileEve: "profile-eve.svg",
  profileKarin: "profile-karin.png",
  profileMystique: "profile-mystique.png",
  profileNathan: "profile-nathan.png"
};

export type CommunityName = keyof typeof communityNameMap;

export const communityUrl = (name: CommunityName) =>
  new URL(`../assets/images/community/${communityNameMap[name]}`, import.meta.url).href;
