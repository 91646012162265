import { Path, PathValue } from "react-hook-form";
import { IntlShape } from "react-intl";

import { Choice } from "@components/v1/fields/StackedButtonLikeInputs";
import { UniversalSearchSortOptionEnum } from "@typing/Enums";
import {
  AutomationActionBlockKindEnum,
  AutomationConditionKindEnum,
  AutomationTriggerBlockFrequencyEnum,
  AutomationTriggerBlockKindEnum,
  AutomationUserTypeEnum,
  BillableCalculationEnum,
  CaregivingDurationEnum,
  CaregivingImpactEnum,
  CaregivingTimePerWeekEnum,
  CareProfileActionableFieldEnum,
  CareProfileAcuityEnum,
  CareProfileCaregiverTypeEnum,
  CareProfileCarePhaseEnum,
  CareProfileCognitiveStatusEnum,
  CareProfileConservatorshipStatusEnum,
  CareProfileCurrentCareTransitionEnum,
  CareProfileDevelopmentalOrEducationalConcernEnum,
  CareProfileEmotionalConcernEnum,
  CareProfileEmploymentStatusEnum,
  CareProfileFallCountEnum,
  CareProfileFinancialManagerEnum,
  CareProfileGuardianTypeEnum,
  CareProfileGuidanceTypeEnum,
  CareProfileHealthInsuranceTypeEnum,
  CareProfileImmigrationStatusEnum,
  CareProfileIncomeSourceEnum,
  CareProfileInsuranceTypeEnum,
  CareProfileMaritalStatusEnum,
  CareProfileMedicationManagementStatusEnum,
  CareProfileProviderFinancialConcernEnum,
  CareProfileReligionEnum,
  CareProfileResidenceTypeEnum,
  CareProfileSenseOfFulfillmentEnum,
  CareProfileSocialSatisfactionEnum,
  CareProfileStabilitySupportEnum,
  CareProfileStageLimitedEnum,
  CareProfileTypesOfSupportNeededEnum,
  CareProfileUrgencyEnum,
  CareProfileUseCaseEnum,
  CareTypeEnum,
  ClientRoleProviderEmotionalStateEnum,
  ClientRoleProviderFeelsSupportedEnum,
  ClientRoleRelationshipEnum,
  ConsultDurationEnum,
  ConsultReportKindEnum,
  ContactMethodEnum,
  DiagnosisControlEnum,
  DiagnosisSeverityEnum,
  DistanceToLovedOneEnum,
  DurableMedicalEquipmentEnum,
  DurationUnitsEnum,
  FocusAreaEnum,
  HouseholdIncomeLevelEnum,
  JourneysSearchDateRangeTypeEnum,
  LegalDocumentKindEnum,
  LegalDocumentStatusEnum,
  MedicationFormatEnum,
  MedicationKindEnum,
  MedicationPaymentTypeEnum,
  PronounsEnum,
  PushNotificationActionEnum,
  SeverityEnum,
  SignedDocumentKindEnum,
  SpecialtyAgeEnum,
  StageEnum,
  TaskClientVisibleEnum,
  TaskKindEnum,
  TrackerTypeEnum,
  WebinarRecurMonthlyTypeEnum,
  WebinarRecurMonthlyWeekEnum,
  WebinarRecurTypeEnum,
  WebinarRecurWeekdayEnum,
  WebinarRegistrationRecurrenceTypeEnum,
  YesNoDontKnowEnum
} from "@typing/Generated";
import { ImageName } from "@utils/imageUtils";
import {
  providerEmotionalStateIcon,
  providerFeelsSupportedIcon,
  senseOfFulfillmentIcon,
  socialSatisfactionIcon
} from "@utils/modelUtils";
import { snakeToCamel } from "@utils/stringUtils";

const translateOptions = <T extends unknown>(options: T[], intl: IntlShape, prefix: string, suffix?: string) =>
  options.map(option => ({
    label: intl.formatMessage({
      id: `${prefix}.${option}${suffix ? `.${suffix}` : ""}`
    }),
    value: option
  }));

export const optionsForAcuity = (intl: IntlShape) => {
  const options = [
    CareProfileAcuityEnum.CHRONIC,
    CareProfileAcuityEnum.COMPLEX,
    CareProfileAcuityEnum.DAILY_NEEDS,
    CareProfileAcuityEnum.EPISODIC
  ];
  return translateOptions(options, intl, "models.careProfile.acuityOptions");
};

export const optionsForAutomationActionBlockKinds = (intl: IntlShape) => {
  const options = [
    AutomationActionBlockKindEnum.APPLY_CARE_PLAN_TASK_TEMPLATE,
    AutomationActionBlockKindEnum.APPLY_GOAL_TEMPLATE,
    AutomationActionBlockKindEnum.COMPLETE_ALL_TASKS_FROM_TEMPLATE,
    AutomationActionBlockKindEnum.COMPLETE_ALL_TASKS_OF_KIND,
    AutomationActionBlockKindEnum.CREATE_MESSAGE,
    AutomationActionBlockKindEnum.CREATE_PUSH_NOTIFICATION,
    AutomationActionBlockKindEnum.CREATE_TASK,
    AutomationActionBlockKindEnum.NO_OP,
    AutomationActionBlockKindEnum.RECOMMEND_AN_ARTICLE,
    AutomationActionBlockKindEnum.RECOMMEND_A_WORKSHEET_TEMPLATE
  ];

  return translateOptions(options, intl, "models.automationActionBlock.kindOptions");
};

export const optionsForAutomationConditionKind = (intl: IntlShape) => {
  const options = [
    AutomationConditionKindEnum.CARE_PLAN_IS_PUBLISHED,
    AutomationConditionKindEnum.CARE_PROFILE_VALUE_IS,
    AutomationConditionKindEnum.FUTURE_CONSULT_SCHEDULED,
    AutomationConditionKindEnum.JOURNEY_IS_ACTIVE,
    AutomationConditionKindEnum.JOURNEY_REQUESTED_LANGUAGE_INTERPRETATION,
    AutomationConditionKindEnum.OWNER_EMPLOYER_IS,
    AutomationConditionKindEnum.MEMBER_MESSAGE_SINCE,
    AutomationConditionKindEnum.NEVER,
    AutomationConditionKindEnum.TIME_SINCE_LAST_CONSULT
  ];
  return translateOptions(options, intl, "models.automationCondition.kinds");
};

export const optionsForAutomationTriggerBlockFrequency = (
  intl: IntlShape,
  availableFrequencies: AutomationTriggerBlockFrequencyEnum[]
) => {
  const options = [AutomationTriggerBlockFrequencyEnum.EVERY_TIME, AutomationTriggerBlockFrequencyEnum.FIRST_TIME_ONLY];

  return translateOptions(
    options.filter(o => availableFrequencies.includes(o)),
    intl,
    "models.automationTriggerBlock.frequencyOptions"
  );
};

export const optionsForAutomationTriggerBlockKinds = (intl: IntlShape) => {
  const options = [
    AutomationTriggerBlockKindEnum.ARTICLE_OPENED,
    AutomationTriggerBlockKindEnum.CARE_PLAN_ACTION_ITEM_COMPLETED,
    AutomationTriggerBlockKindEnum.CARE_PLAN_PUBLISHED,
    AutomationTriggerBlockKindEnum.CARE_PROFILE_UPDATED,
    AutomationTriggerBlockKindEnum.CARE_TEAM_INVITE_ACCEPTED,
    AutomationTriggerBlockKindEnum.CARE_TEAM_INVITE_SENT,
    AutomationTriggerBlockKindEnum.CLIENT_LOCATION_CHANGED,
    AutomationTriggerBlockKindEnum.CLIENT_RELATIONSHIP_CHANGED,
    AutomationTriggerBlockKindEnum.CONSULT_CANCELED,
    AutomationTriggerBlockKindEnum.CONSULT_REPORT_PUBLISHED,
    AutomationTriggerBlockKindEnum.CONSULT_SCHEDULED,
    AutomationTriggerBlockKindEnum.EMPLOYER_BENEFIT_OPENED,
    AutomationTriggerBlockKindEnum.FILE_RESOURCE_ADDED,
    AutomationTriggerBlockKindEnum.FREE_TEXT_KEYWORD_MATCHED,
    AutomationTriggerBlockKindEnum.GOAL_ADDED,
    AutomationTriggerBlockKindEnum.INITIAL_EXPERT_ASSIGNED,
    AutomationTriggerBlockKindEnum.JOURNEY_ADDED,
    AutomationTriggerBlockKindEnum.JOURNEY_TAG_ADDED,
    AutomationTriggerBlockKindEnum.LOSES_EXPERT_ACCESS_UPCOMING,
    AutomationTriggerBlockKindEnum.MESSAGE_SENT,
    AutomationTriggerBlockKindEnum.NEVER,
    AutomationTriggerBlockKindEnum.PACKAGE_GRANT_PURCHASED,
    AutomationTriggerBlockKindEnum.PACKAGE_GRANT_EXPIRATION_UPCOMING,
    AutomationTriggerBlockKindEnum.PACKAGE_GRANT_ADDED,
    AutomationTriggerBlockKindEnum.RECOMMENDED_NEXT_CONSULT_UPCOMING,
    AutomationTriggerBlockKindEnum.RESOURCE_ADDED,
    AutomationTriggerBlockKindEnum.RESOURCE_MARKED_NOT_USEFUL,
    AutomationTriggerBlockKindEnum.RESOURCE_MARKED_USEFUL,
    AutomationTriggerBlockKindEnum.RESOURCE_PINNED,
    AutomationTriggerBlockKindEnum.RESOURCE_UNPINNED,
    AutomationTriggerBlockKindEnum.SIGNED_DOCUMENT_COMPLETED,
    AutomationTriggerBlockKindEnum.TASK_ADDED,
    AutomationTriggerBlockKindEnum.TASK_COMPLETED,
    AutomationTriggerBlockKindEnum.TIME_LOG_ADDED,
    AutomationTriggerBlockKindEnum.TIME_SINCE_LAST_CONSULT,
    AutomationTriggerBlockKindEnum.TRACKER_COMPLETED,
    AutomationTriggerBlockKindEnum.TRACKER_STARTED,
    AutomationTriggerBlockKindEnum.WORKSHEET_COMPLETED,
    AutomationTriggerBlockKindEnum.WORKSHEET_INCOMPLETED,
    AutomationTriggerBlockKindEnum.WORKSHEET_STARTED
  ];

  return translateOptions(options, intl, "models.automationTriggerBlock.kindOptions");
};

export const optionsForAutomationUserType = (
  intl: IntlShape,
  availableTypes: AutomationUserTypeEnum[] = Object.values(AutomationUserTypeEnum)
) => {
  const options = [
    AutomationUserTypeEnum.ANYONE,
    AutomationUserTypeEnum.CLIENT_ANY,
    AutomationUserTypeEnum.CLIENT_OWNER,
    AutomationUserTypeEnum.EXPERT_ANY,
    AutomationUserTypeEnum.EXPERT_PRIMARY
  ];

  return translateOptions(
    options.filter(o => availableTypes.includes(o)),
    intl,
    "models.automationTriggerBlock.userTypeOptions"
  );
};

export const optionsForBillableCalculation = (intl: IntlShape) =>
  translateOptions(Object.values(BillableCalculationEnum), intl, "models.employerContract.billableCalculation");

export const optionsForBoolean = (intl: IntlShape) => [
  { label: intl.formatMessage({ id: "dictionary.yes" }), value: true },
  { label: intl.formatMessage({ id: "dictionary.no" }), value: false }
];

export const optionsForCaregivingDuration = (intl: IntlShape) => {
  const options = [
    CaregivingDurationEnum.LESS_THAN_ONE_YEAR,
    CaregivingDurationEnum.ONE_TO_TWO_YEARS,
    CaregivingDurationEnum.TWO_TO_THREE_YEARS,
    CaregivingDurationEnum.FOUR_TO_FIVE_YEARS,
    CaregivingDurationEnum.SIX_OR_MORE_YEARS
  ];
  return translateOptions(options, intl, "models.careProfile.caregivingDurationOptions");
};

export const optionsForCaregivingImpact = (intl: IntlShape) => {
  const options = [
    CaregivingImpactEnum.HIGH,
    CaregivingImpactEnum.MEDIUM,
    CaregivingImpactEnum.LOW,
    CaregivingImpactEnum.NONE,
    CaregivingImpactEnum.UNKNOWN
  ];
  return translateOptions(options, intl, "models.careProfile.caregivingImpactOptions");
};

export const optionsForCaregivingTimePerWeek = (intl: IntlShape) => {
  const options = [
    CaregivingTimePerWeekEnum.LESS_THAN_TWO_HOURS,
    CaregivingTimePerWeekEnum.TWO_TO_FOUR_HOURS,
    CaregivingTimePerWeekEnum.FIVE_TO_TEN_HOURS,
    CaregivingTimePerWeekEnum.ELEVEN_TO_TWENTY_HOURS,
    CaregivingTimePerWeekEnum.TWENTY_TO_THIRTY_HOURS,
    CaregivingTimePerWeekEnum.IT_DEPENDS
  ];
  return translateOptions(options, intl, "models.careProfile.caregivingTimePerWeekOptions");
};

export const optionsForCaregivingTimePerWeekGrieving = (intl: IntlShape) => {
  const options = [
    CaregivingTimePerWeekEnum.LESS_THAN_ONE_HOUR,
    CaregivingTimePerWeekEnum.ONE_TO_TWO_HOURS,
    CaregivingTimePerWeekEnum.TWO_TO_FOUR_HOURS,
    CaregivingTimePerWeekEnum.FOUR_OR_MORE_HOURS
  ];
  return translateOptions(options, intl, "models.careProfile.caregivingTimePerWeekOptions");
};

export const optionsForCareProfileActionableField = (intl: IntlShape) => {
  const options = [
    CareProfileActionableFieldEnum.CAREGIVER_TYPES,
    CareProfileActionableFieldEnum.COGNITIVE_STATUSES,
    CareProfileActionableFieldEnum.CURRENT_CARE_TRANSITIONS,
    CareProfileActionableFieldEnum.EMOTIONAL_CONCERNS,
    CareProfileActionableFieldEnum.FALL_COUNT,
    CareProfileActionableFieldEnum.FINANCIAL_MANAGER,
    CareProfileActionableFieldEnum.GUIDANCE_TYPE,
    CareProfileActionableFieldEnum.HEALTH_INSURANCE_TYPES,
    CareProfileActionableFieldEnum.INSURANCE_TYPES,
    CareProfileActionableFieldEnum.LEGAL_HELP_NEEDED,
    CareProfileActionableFieldEnum.MARITAL_STATUS,
    CareProfileActionableFieldEnum.MEDICATIONS_AFFORDABLE,
    CareProfileActionableFieldEnum.PROVIDER_FINANCIAL_CONCERNS,
    CareProfileActionableFieldEnum.RECENT_HOSPITALIZATION,
    CareProfileActionableFieldEnum.RELIGION,
    CareProfileActionableFieldEnum.RESIDENCE_TYPE,
    CareProfileActionableFieldEnum.SENSE_OF_FULFILLMENT,
    CareProfileActionableFieldEnum.SOCIAL_SATISFACTION,
    CareProfileActionableFieldEnum.STABILITY_SUPPORTS,
    CareProfileActionableFieldEnum.TYPES_OF_SUPPORT_NEEDED,
    CareProfileActionableFieldEnum.URGENCY,
    CareProfileActionableFieldEnum.USE_CASE
  ];

  return options.map(option => ({
    label: intl.formatMessage({ id: `models.careProfile.${snakeToCamel(option)}` }),
    value: option
  }));
};

export const optionsForCareTypes = (intl: IntlShape) =>
  translateOptions(Object.values(CareTypeEnum), intl, "models.careProfile.careTypesProvidedOptions");

export const optionsForCaregiverType = (intl: IntlShape) => {
  const options = [
    CareProfileCaregiverTypeEnum.CHILDREN,
    CareProfileCaregiverTypeEnum.SPOUSE,
    CareProfileCaregiverTypeEnum.SIBLINGS,
    CareProfileCaregiverTypeEnum.PARENTS,
    CareProfileCaregiverTypeEnum.STEP_PARENTS,
    CareProfileCaregiverTypeEnum.GRANDPARENTS,
    CareProfileCaregiverTypeEnum.RELATIVES,
    CareProfileCaregiverTypeEnum.FRIENDS,
    CareProfileCaregiverTypeEnum.CAREGIVERS,
    CareProfileCaregiverTypeEnum.NEIGHBORS,
    CareProfileCaregiverTypeEnum.OTHER
  ];
  return translateOptions(options, intl, "models.careProfile.caregiverTypesOptions");
};

export const optionsForCognitiveStatus = (intl: IntlShape) => {
  const options = [
    CareProfileCognitiveStatusEnum.NONE,
    CareProfileCognitiveStatusEnum.FORGETFUL,
    CareProfileCognitiveStatusEnum.SHORT_TERM_MEMORY_LOSS,
    CareProfileCognitiveStatusEnum.LONG_TERM_MEMORY_LOSS
  ];

  return translateOptions(options, intl, "models.careProfile.cognitiveStatusesOptions");
};

export const optionsForConservatorshipStatus = (intl: IntlShape) => {
  const options = [
    CareProfileConservatorshipStatusEnum.CONSERVED,
    CareProfileConservatorshipStatusEnum.NOT_CONSERVED,
    CareProfileConservatorshipStatusEnum.NOT_ADDRESSED
  ];

  return translateOptions(options, intl, "models.careProfile.conservatorshipStatusOptions");
};

export const optionsForConsultReportKind = (intl: IntlShape) => {
  const options = [
    ConsultReportKindEnum.ANY,
    ConsultReportKindEnum.CONSULT_REPORT,
    ConsultReportKindEnum.INITIAL_CONSULT_REPORT
  ];

  return translateOptions(options, intl, "models.automationTriggerBlock.consultReportKindOptions");
};

export const optionsForCurrentCareTransition = (intl: IntlShape) => {
  const options = [
    { option: CareProfileCurrentCareTransitionEnum.NONE },
    { option: CareProfileCurrentCareTransitionEnum.ADDING_PART_TIME_CARE },
    { option: CareProfileCurrentCareTransitionEnum.ADDING_FULL_TIME_CARE },
    { option: CareProfileCurrentCareTransitionEnum.REDUCING_CARE },
    { option: CareProfileCurrentCareTransitionEnum.MOVING },
    { option: CareProfileCurrentCareTransitionEnum.TRANSFERRING },
    { option: CareProfileCurrentCareTransitionEnum.GOING_HOME },
    { option: CareProfileCurrentCareTransitionEnum.OTHER }
  ];
  return options.map(option => ({
    label: intl.formatMessage({
      id: `models.careProfile.currentCareTransitionsOptions.${option.option}`
    }),
    value: option.option
  }));
};

export const optionsForDevelopmentalOrEducationalConcerns = (intl: IntlShape) =>
  translateOptions(
    Object.values(CareProfileDevelopmentalOrEducationalConcernEnum),
    intl,
    "models.careProfile.developmentalOrEducationalConcernsOptions"
  );

export const optionsForDiagnosisControl = (intl: IntlShape) => {
  const options = [
    DiagnosisControlEnum.CONTROLLED,
    DiagnosisControlEnum.UNCONTROLLED,
    DiagnosisControlEnum.UNKNOWN,
    DiagnosisControlEnum.NA
  ];
  return translateOptions(options, intl, "models.diagnosis.controlOptions");
};

export const optionsForDiagnosisSeverity = (intl: IntlShape) => {
  const options = [
    DiagnosisSeverityEnum.MILD,
    DiagnosisSeverityEnum.MODERATE,
    DiagnosisSeverityEnum.SEVERE,
    DiagnosisSeverityEnum.UNKNOWN,
    DiagnosisSeverityEnum.NA
  ];

  return translateOptions(options, intl, "models.diagnosis.severityOptions");
};

export const optionsForDistanceToLovedOne = (intl: IntlShape) => {
  const options = [
    DistanceToLovedOneEnum.SAME_HOUSEHOLD,
    DistanceToLovedOneEnum.SAME_CITY,
    DistanceToLovedOneEnum.SAME_STATE,
    DistanceToLovedOneEnum.SAME_COUNTRY,
    DistanceToLovedOneEnum.DIFFERENT_COUNTRY
  ];

  return translateOptions(options, intl, "models.careProfile.distanceToLovedOneOptions");
};

export const optionsForDurableMedicalEquipment = (intl: IntlShape) =>
  translateOptions(
    Object.values(DurableMedicalEquipmentEnum),
    intl,
    "models.careProfile.durableMedicalEquipmentOptions"
  );

export const optionsForDurationUnits = (intl: IntlShape, defaultOptions?: DurationUnitsEnum[]) => {
  const options = defaultOptions ?? [DurationUnitsEnum.MINUTES, DurationUnitsEnum.HOURS, DurationUnitsEnum.DAYS];

  return translateOptions(options, intl, "models.automationTimingBlock.durationOptions");
};

export const optionsForEmotionalConcern = (intl: IntlShape) => {
  const options = [
    CareProfileEmotionalConcernEnum.DEPRESSION,
    CareProfileEmotionalConcernEnum.ANXIETY,
    CareProfileEmotionalConcernEnum.ISOLATION,
    CareProfileEmotionalConcernEnum.WEIGHT_CHANGES,
    CareProfileEmotionalConcernEnum.SLEEP_PROBLEMS,
    CareProfileEmotionalConcernEnum.NONE,
    CareProfileEmotionalConcernEnum.OTHER
  ];
  return translateOptions(options, intl, "models.careProfile.emotionalConcernsOptions");
};

export const optionsForEmploymentStatus = (intl: IntlShape) =>
  translateOptions(Object.values(CareProfileEmploymentStatusEnum), intl, "models.careProfile.employmentStatusOptions");

export const optionsForFallCount = (intl: IntlShape) => {
  const options = [
    CareProfileFallCountEnum.NONE,
    CareProfileFallCountEnum.TWO_OR_FEWER,
    CareProfileFallCountEnum.THREE_OR_MORE
  ];

  return translateOptions(options, intl, "models.careProfile.fallCountOptions");
};

export const optionsForFinancialManager = (intl: IntlShape) => {
  const options = [
    CareProfileFinancialManagerEnum.LOVED_ONE,
    CareProfileFinancialManagerEnum.RELATIVE,
    CareProfileFinancialManagerEnum.FIDUCIARY,
    CareProfileFinancialManagerEnum.UNSURE,
    CareProfileFinancialManagerEnum.OTHER
  ];
  return translateOptions(options, intl, "models.careProfile.financialManagerOptions");
};

export const optionsForFocusArea = (intl: IntlShape, allOption = true) => {
  const options = Object.values(FocusAreaEnum).map(option => ({
    label: intl.formatMessage({ id: `models.focusArea.${option}` }),
    value: option as string
  }));
  if (allOption) {
    options.unshift({ label: intl.formatMessage({ id: "dictionary.all" }), value: "" });
  }
  return options;
};

export const optionsForGuardianType = (intl: IntlShape) =>
  translateOptions(Object.values(CareProfileGuardianTypeEnum), intl, "models.careProfile.guardianTypeOptions");

export const optionsForGuidanceType = <T,>(
  intl: IntlShape,
  handleChange?: (value: CareProfileGuidanceTypeEnum) => T
) => {
  const options = [
    { value: CareProfileGuidanceTypeEnum.EXPERT_GUIDED },
    { value: CareProfileGuidanceTypeEnum.SELF_GUIDED }
  ];

  return options.map(option => ({
    label: intl.formatMessage({ id: `models.careProfile.guidanceTypeOptions.${option.value}` }),
    onChange: () => {
      handleChange?.(option.value);
    },
    value: option.value
  }));
};

export const optionsForHealthInsuranceType = (intl: IntlShape) => {
  const options = [
    CareProfileHealthInsuranceTypeEnum.MEDICARE,
    CareProfileHealthInsuranceTypeEnum.SUPPLEMENTAL,
    CareProfileHealthInsuranceTypeEnum.MEDICAID,
    CareProfileHealthInsuranceTypeEnum.PRIVATE,
    CareProfileHealthInsuranceTypeEnum.VETERAN,
    CareProfileHealthInsuranceTypeEnum.OTHER,
    CareProfileHealthInsuranceTypeEnum.NONE
  ];
  return translateOptions(options, intl, "models.careProfile.healthInsuranceTypesOptions");
};

export const optionsForHour = (intl: IntlShape) => {
  const options = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

  return translateOptions(options, intl, "models.hour.hourOptions");
};

export const optionsForHouseholdIncomeLevel = (intl: IntlShape) =>
  translateOptions(Object.values(HouseholdIncomeLevelEnum), intl, "models.careProfile.householdIncomeLevelOptions");

export const optionsForImmigrationStatus = (intl: IntlShape) => {
  const options = [
    CareProfileImmigrationStatusEnum.CITIZEN,
    CareProfileImmigrationStatusEnum.CONDITIONAL_RESIDENT,
    CareProfileImmigrationStatusEnum.NON_IMMIGRANT_OR_WORK_VISA,
    CareProfileImmigrationStatusEnum.PERMANENT_RESIDENT,
    CareProfileImmigrationStatusEnum.UNDOCUMENTED
  ];
  return translateOptions(options, intl, "models.careProfile.immigrationStatusOptions");
};

export const optionsForIncomeSource = (intl: IntlShape) =>
  translateOptions(Object.values(CareProfileIncomeSourceEnum), intl, "models.careProfile.incomeSourcesOptions");

export const optionsForInsuranceType = (intl: IntlShape) => {
  const options = [
    CareProfileInsuranceTypeEnum.LIFE_INSURANCE,
    CareProfileInsuranceTypeEnum.LONG_TERM_CARE_INSURANCE,
    CareProfileInsuranceTypeEnum.OTHER,
    CareProfileInsuranceTypeEnum.NONE
  ];
  return translateOptions(options, intl, "models.careProfile.insuranceTypesOptions");
};

export const optionsForIsVeteran = (intl: IntlShape) => [
  { label: intl.formatMessage({ id: "models.careProfile.isVeteranOptions.yes" }), value: true },
  { label: intl.formatMessage({ id: "models.careProfile.isVeteranOptions.no" }), value: false }
];

export const optionsForJourneySearchDateRangeType = (intl: IntlShape) => {
  const options = [JourneysSearchDateRangeTypeEnum.CREATED_AT, JourneysSearchDateRangeTypeEnum.LAST_ACTIVITY_AT];
  return translateOptions(options, intl, "models.journeysSearch.dateRangeTypes");
};

export const optionsForJourneySearchSortBy = (intl: IntlShape) => {
  const options = [
    UniversalSearchSortOptionEnum.SCORE,
    UniversalSearchSortOptionEnum.LAST_ACTIVITY_AT_DESC,
    UniversalSearchSortOptionEnum.LAST_ACTIVITY_AT_ASC,
    UniversalSearchSortOptionEnum.CREATED_AT_DESC,
    UniversalSearchSortOptionEnum.CREATED_AT_ASC
  ];
  return translateOptions(options, intl, "models.journeysSearch.sortBy");
};

export const optionsForLegalDocumentKind = (intl: IntlShape, availableKinds?: LegalDocumentKindEnum[]) =>
  translateOptions(availableKinds ?? Object.values(LegalDocumentKindEnum), intl, "models.legalDocument.kindOptions");

export const optionsForLegalDocumentStatus = (intl: IntlShape) =>
  Object.values(LegalDocumentStatusEnum).map(option => ({
    label: intl.formatMessage({ id: `models.legalDocument.statusOptions.${option}` }),
    value: option
  }));

export const optionsForMaritalStatus = (intl: IntlShape) => {
  const options = [
    CareProfileMaritalStatusEnum.SINGLE,
    CareProfileMaritalStatusEnum.MARRIED,
    CareProfileMaritalStatusEnum.DOMESTIC_PARTNER,
    CareProfileMaritalStatusEnum.DIVORCED,
    CareProfileMaritalStatusEnum.SEPARATED,
    CareProfileMaritalStatusEnum.WIDOWED,
    CareProfileMaritalStatusEnum.OTHER
  ];
  return translateOptions(options, intl, "models.careProfile.maritalStatusOptions");
};

export const optionsForMedicationKind = (intl: IntlShape) => {
  const options = [
    MedicationKindEnum.PRESCRIPTION,
    MedicationKindEnum.OVER_THE_COUNTER,
    MedicationKindEnum.VITAMIN_OR_SUPPLEMENT
  ];

  return translateOptions(options, intl, "models.medication.kindOptions");
};

export const optionsForMedicationFormat = (intl: IntlShape) => {
  const options = [
    MedicationFormatEnum.INJECTION,
    MedicationFormatEnum.ORAL,
    MedicationFormatEnum.SUPPOSITORY,
    MedicationFormatEnum.TOPICAL,
    MedicationFormatEnum.OTHER
  ];
  return translateOptions(options, intl, "models.medication.formatOptions");
};

export const optionsForMedicationPaymentType = (intl: IntlShape) => {
  const options = [
    MedicationPaymentTypeEnum.COVERED_BY_INSURANCE,
    MedicationPaymentTypeEnum.SELF_PAY,
    MedicationPaymentTypeEnum.UNKNOWN
  ];
  return translateOptions(options, intl, "models.medication.paymentTypeOptions");
};

export const optionsForMedicationManagementStatus = (intl: IntlShape) =>
  translateOptions(
    Object.values(CareProfileMedicationManagementStatusEnum),
    intl,
    "models.careProfile.medicationManagementStatusOptions"
  );

export const optionsForPronouns = (intl: IntlShape) => {
  const options = [
    PronounsEnum.HE_HIM_HIS,
    PronounsEnum.SHE_HER_HERS,
    PronounsEnum.THEY_THEM_THEIRS,
    PronounsEnum.ZE_HIR_HIR,
    PronounsEnum.PER_PER_PERS,
    PronounsEnum.IT_IT_ITS,
    PronounsEnum.DO_NOT_USE_PRONOUNS,
    PronounsEnum.OTHER
  ];
  return translateOptions(options, intl, "models.careProfile.pronounsOptions");
};

export const optionsForProviderEmotionalState = (intl: IntlShape) => {
  const options = [
    ClientRoleProviderEmotionalStateEnum.VERY_NEGATIVE,
    ClientRoleProviderEmotionalStateEnum.NEGATIVE,
    ClientRoleProviderEmotionalStateEnum.NEUTRAL,
    ClientRoleProviderEmotionalStateEnum.POSITIVE,
    ClientRoleProviderEmotionalStateEnum.VERY_POSITIVE
  ];
  return options.map(option => ({
    iconName: providerEmotionalStateIcon(option),
    label: intl.formatMessage({
      id: `models.careProfile.providerEmotionalStateOptions.${option}`
    }),
    value: option
  }));
};

export const optionsForProviderFeelsSupported = (intl: IntlShape) => {
  const options = [
    { value: ClientRoleProviderFeelsSupportedEnum.YES },
    { value: ClientRoleProviderFeelsSupportedEnum.NO },
    { value: ClientRoleProviderFeelsSupportedEnum.ITS_COMPLICATED }
  ];
  return options.map(option => ({
    iconName: providerFeelsSupportedIcon(option.value),
    label: intl.formatMessage({
      id: `models.careProfile.providerFeelsSupportedOptions.${option.value}`
    }),
    value: option.value
  }));
};

export const optionsForProviderFinancialConcerns = (intl: IntlShape) => {
  const options = [
    CareProfileProviderFinancialConcernEnum.YES,
    CareProfileProviderFinancialConcernEnum.NO,
    CareProfileProviderFinancialConcernEnum.NOT_ADDRESSED
  ];
  return translateOptions(options, intl, "models.careProfile.providerFinancialConcernsOptions");
};

export const optionsForRelationship = (
  intl: IntlShape,
  translationPrefix: string,
  skipOther = false,
  includeImages = false,
  translationValues: Record<string, string> | undefined = undefined
) => {
  let options = [
    { image: "huggingHeart", value: ClientRoleRelationshipEnum.MYSELF },
    { image: "givingFlowers", value: ClientRoleRelationshipEnum.SPOUSE },
    { image: "domesticPartner", value: ClientRoleRelationshipEnum.DOMESTIC_PARTNER },
    { image: "manSittingWithLaptop", value: ClientRoleRelationshipEnum.PARENT },
    { image: "family", value: ClientRoleRelationshipEnum.PARENT_IN_LAW },
    { image: "dancingSeniors", value: ClientRoleRelationshipEnum.GRANDCHILD },
    { image: "manWithSenior", value: ClientRoleRelationshipEnum.GRANDPARENT },
    { image: "happyFamily", value: ClientRoleRelationshipEnum.CHILD },
    { image: "fosterParent", value: ClientRoleRelationshipEnum.FOSTER_CHILD },
    { image: "friendOfParent", value: ClientRoleRelationshipEnum.CHILD_OF_FRIEND_OR_RELATIVE },
    { image: "happyWomenJumping", value: ClientRoleRelationshipEnum.SIBLING },
    { image: "auntOrUncle", value: ClientRoleRelationshipEnum.NIECE_OR_NEPHEW },
    { image: "manHoldingLargePhone", value: ClientRoleRelationshipEnum.RELATIVE },
    { image: "fishingInBoat", value: ClientRoleRelationshipEnum.FRIEND },
    { image: "womanChairComputer", value: ClientRoleRelationshipEnum.OTHER }
  ];

  if (skipOther) {
    options = options.filter(option => option.value !== ClientRoleRelationshipEnum.OTHER);
  }
  return options.map(option => ({
    image: includeImages ? (option.image as ImageName) : undefined,
    label: intl.formatMessage({ id: `${translationPrefix}.${option.value}` }, translationValues),
    value: option.value
  }));
};

export const optionsForReligion = (intl: IntlShape) => {
  const options = [
    CareProfileReligionEnum.NONE,
    CareProfileReligionEnum.ATHEISM,
    CareProfileReligionEnum.BAHAI,
    CareProfileReligionEnum.BUDDHISM,
    CareProfileReligionEnum.CATHOLICISM,
    CareProfileReligionEnum.CHRISTIANITY,
    CareProfileReligionEnum.HINDUISM,
    CareProfileReligionEnum.ISLAM,
    CareProfileReligionEnum.JAINISM,
    CareProfileReligionEnum.JEHOVAHS_WITNESS,
    CareProfileReligionEnum.JUDAISM,
    CareProfileReligionEnum.MORMONISM,
    CareProfileReligionEnum.OTHER,
    CareProfileReligionEnum.PAGANISM,
    CareProfileReligionEnum.RASTAFARI,
    CareProfileReligionEnum.SANTERIA,
    CareProfileReligionEnum.SHINTO,
    CareProfileReligionEnum.SIKHISM,
    CareProfileReligionEnum.SPIRITUALISM,
    CareProfileReligionEnum.TAOISM,
    CareProfileReligionEnum.UNITARIANISM,
    CareProfileReligionEnum.ZOROASTRIANISM
  ];
  return translateOptions(options, intl, "models.careProfile.religionOptions");
};

export const optionsForResidenceType = (intl: IntlShape) => {
  const options = [
    { option: CareProfileResidenceTypeEnum.HOME_ALONE },
    { option: CareProfileResidenceTypeEnum.HOME_WITH_FAMILY },
    { option: CareProfileResidenceTypeEnum.HOME_WITH_OTHERS },
    { option: CareProfileResidenceTypeEnum.GROUP_HOME },
    { option: CareProfileResidenceTypeEnum.INDEPENDENT_LIVING_COMMUNITY },
    { option: CareProfileResidenceTypeEnum.ASSISTED_LIVING_COMMUNITY },
    { option: CareProfileResidenceTypeEnum.MEMORY_CARE_COMMUNITY },
    { option: CareProfileResidenceTypeEnum.SKILLED_NURSING_FACILITY },
    { option: CareProfileResidenceTypeEnum.FOSTER_CARE },
    { option: CareProfileResidenceTypeEnum.UNHOUSED },
    { option: CareProfileResidenceTypeEnum.OTHER }
  ];
  return options.map(option => ({
    label: intl.formatMessage({
      id: `models.careProfile.residenceTypeOptions.${option.option}`
    }),
    value: option.option
  }));
};

export const optionsForSenseOfFulfillment = (intl: IntlShape) => {
  const options = [
    CareProfileSenseOfFulfillmentEnum.NOT_AT_ALL,
    CareProfileSenseOfFulfillmentEnum.LEVEL_2,
    CareProfileSenseOfFulfillmentEnum.NEUTRAL,
    CareProfileSenseOfFulfillmentEnum.LEVEL_4,
    CareProfileSenseOfFulfillmentEnum.EXTREMELY,
    CareProfileSenseOfFulfillmentEnum.NOT_ADDRESSED
  ];
  return options.map(option => ({
    iconName: senseOfFulfillmentIcon(option),
    label: intl.formatMessage({
      id: `models.careProfile.senseOfFulfillmentOptions.${option}`
    }),
    value: option
  }));
};

export const optionsForSocialSatisfaction = (intl: IntlShape) => {
  const options = [
    CareProfileSocialSatisfactionEnum.NOT_AT_ALL,
    CareProfileSocialSatisfactionEnum.LEVEL_2,
    CareProfileSocialSatisfactionEnum.NEUTRAL,
    CareProfileSocialSatisfactionEnum.LEVEL_4,
    CareProfileSocialSatisfactionEnum.EXTREMELY,
    CareProfileSocialSatisfactionEnum.NOT_ADDRESSED
  ];

  return options.map(option => ({
    iconName: socialSatisfactionIcon(option),
    label: intl.formatMessage({
      id: `models.careProfile.socialSatisfactionOptions.${option}`
    }),
    value: option
  }));
};

export const optionsForSeverity = (intl: IntlShape) =>
  translateOptions(Object.values(SeverityEnum), intl, "models.careProfile.severityOptions");

export const optionsForSpecialtyAge = (intl: IntlShape) => {
  const options = [
    SpecialtyAgeEnum.SIXTY_FIVE_AND_OLDER,
    SpecialtyAgeEnum.EIGHTEEN_TO_SIXTY_FOUR,
    SpecialtyAgeEnum.UNDER_EIGHTEEN,
    SpecialtyAgeEnum.DECEASED
  ];

  return options.map(option => ({
    color: "#5f49d3",
    label: intl.formatMessage({ id: `models.specialties.age.${option}` }),
    value: option
  }));
};

export const optionsForStage = (intl: IntlShape) => {
  const options = [
    StageEnum.INDEPENDENT,
    StageEnum.INTERDEPENDENT,
    StageEnum.DEPENDENT,
    StageEnum.CRISIS,
    StageEnum.END_OF_LIFE,
    StageEnum.BEREAVEMENT
  ];
  return translateOptions(options, intl, "models.journey.stage");
};

export const optionsForStageLimited = (intl: IntlShape) => {
  const options = [
    CareProfileStageLimitedEnum.INDEPENDENT,
    CareProfileStageLimitedEnum.INTERDEPENDENT,
    CareProfileStageLimitedEnum.DEPENDENT
  ];
  return translateOptions(options, intl, "models.careProfile.stageLimited");
};

export const optionsForStabilitySupport = (intl: IntlShape) => {
  const options = [
    CareProfileStabilitySupportEnum.CANE_OR_WALKER,
    CareProfileStabilitySupportEnum.WHEELCHAIR,
    CareProfileStabilitySupportEnum.OBJECTS,
    CareProfileStabilitySupportEnum.NONE
  ];

  return translateOptions(options, intl, "models.careProfile.stabilitySupportsOptions");
};

export const optionsForUrgency = <T,>(intl: IntlShape, handleChange?: (value: CareProfileUrgencyEnum) => T) => {
  const options = [
    { value: CareProfileUrgencyEnum.ASAP },
    { value: CareProfileUrgencyEnum.SOON },
    { value: CareProfileUrgencyEnum.JUST_BROWSING }
  ];
  return options.map(option => ({
    label: intl.formatMessage({ id: `models.careProfile.urgencyOptions.${option.value}` }),
    onChange: () => {
      handleChange?.(option.value);
    },
    value: option.value
  }));
};

export const optionsForTaskKinds = (intl: IntlShape) => {
  const options = Object.values(TaskKindEnum);

  return translateOptions(options, intl, "models.tasks.kind");
};

export const optionsForPushNotificationAction = (intl: IntlShape) => {
  const options = [
    PushNotificationActionEnum.OPEN_CARE_PLAN,
    PushNotificationActionEnum.OPEN_CONSULTS,
    PushNotificationActionEnum.OPEN_MESSAGES
  ];

  return translateOptions(options, intl, "models.pushNotificationActions");
};

export const optionsForSignedDocumentKind = (intl: IntlShape) => {
  const options = [SignedDocumentKindEnum.CONSENT_FORM];

  return translateOptions(options, intl, "models.signedDocument.kindOptions");
};

export const optionsForTaskClientVisible = (intl: IntlShape) => {
  const options = [
    TaskClientVisibleEnum.ANY_TASK,
    TaskClientVisibleEnum.CLIENT_VISIBLE,
    TaskClientVisibleEnum.EXPERT_ONLY
  ];

  return translateOptions(options, intl, "models.tasks.taskClientVisibleOptions");
};

export const optionsForTrackerType = (intl: IntlShape) => {
  const options = [
    TrackerTypeEnum.LEGAL_DOCUMENTS,
    TrackerTypeEnum.MEDICATION_TRACKER,
    TrackerTypeEnum.PROVIDER_TRACKER
  ];

  return translateOptions(options, intl, "models.tracker.typeOptions");
};

export const optionsForTypesOfSupportNeeded = (intl: IntlShape) =>
  translateOptions(
    Object.values(CareProfileTypesOfSupportNeededEnum),
    intl,
    "models.careProfile.typesOfSupportNeededOptions"
  );

export const optionsForUseCase = (intl: IntlShape, isMulti = false) => {
  const options = [
    CareProfileUseCaseEnum.GRIEVING,
    CareProfileUseCaseEnum.AGING_LOVED_ONE,
    CareProfileUseCaseEnum.ADULT,
    CareProfileUseCaseEnum.SELF,
    CareProfileUseCaseEnum.CHILD,
    CareProfileUseCaseEnum.INFANT,
    CareProfileUseCaseEnum.PREGNANCY
  ];
  const translatedOptions = translateOptions(options, intl, "models.careProfile.useCaseOptions");
  return isMulti
    ? translatedOptions.map(option => ({
        ...option,
        color: "#6ab7d0"
      }))
    : translatedOptions;
};

export const optionsForCarePhase = (intl: IntlShape) => {
  const options = [CareProfileCarePhaseEnum.ACTIVELY_CARING, CareProfileCarePhaseEnum.PLANNING_CARE];
  return translateOptions(options, intl, "models.careProfile.carePhaseOptions");
};

export const optionsForWebinarRecurMonthlyType = (intl: IntlShape) => {
  const options = [WebinarRecurMonthlyTypeEnum.DAY_OF_MONTH, WebinarRecurMonthlyTypeEnum.WEEK_AND_WEEKDAY];
  return translateOptions(options, intl, "models.article.webinarRecurMonthlyTypeOptions");
};

export const optionsForWebinarRecurMonthlyWeek = (intl: IntlShape) => {
  const options = [
    WebinarRecurMonthlyWeekEnum.FIRST_WEEK,
    WebinarRecurMonthlyWeekEnum.SECOND_WEEK,
    WebinarRecurMonthlyWeekEnum.THIRD_WEEK,
    WebinarRecurMonthlyWeekEnum.FOURTH_WEEK,
    WebinarRecurMonthlyWeekEnum.LAST_WEEK
  ];
  return translateOptions(options, intl, "models.article.webinarRecurMonthlyWeekOptions");
};

export const optionsForWebinarRecurType = (intl: IntlShape, includeNone = true) => {
  const options = [WebinarRecurTypeEnum.DAILY, WebinarRecurTypeEnum.WEEKLY, WebinarRecurTypeEnum.MONTHLY];
  if (includeNone) options.unshift(WebinarRecurTypeEnum.NONE);

  return translateOptions(options, intl, "models.article.webinarRecurTypeOptions");
};

export const optionsForWebinarRecurWeekday = (intl: IntlShape) => {
  const options = [
    WebinarRecurWeekdayEnum.SUNDAY,
    WebinarRecurWeekdayEnum.MONDAY,
    WebinarRecurWeekdayEnum.TUESDAY,
    WebinarRecurWeekdayEnum.WEDNESDAY,
    WebinarRecurWeekdayEnum.THURSDAY,
    WebinarRecurWeekdayEnum.FRIDAY,
    WebinarRecurWeekdayEnum.SATURDAY
  ];
  return translateOptions(options, intl, "models.article.webinarRecurWeekdayOptions");
};

export const optionsForWebinarRegistrationRecurrenceType = (intl: IntlShape) => {
  const options = [
    WebinarRegistrationRecurrenceTypeEnum.ALL_FUTURE_OCCURRENCES,
    WebinarRegistrationRecurrenceTypeEnum.SINGLE_OCCURRENCE
  ];
  return translateOptions(options, intl, "models.article.webinarRegistrationRecurrenceTypeOptions");
};

export const optionsForYesNoDontKnow = (intl: IntlShape) => {
  const options = [YesNoDontKnowEnum.YES, YesNoDontKnowEnum.NO, YesNoDontKnowEnum.I_DONT_KNOW];

  return translateOptions(options, intl, "models.careProfile.yesNoDontKnowOptions");
};

type YesNoDontKnowFormData = Record<string, YesNoDontKnowEnum | null>;

export const optionsForYesNoDontKnowForOnboarding = <FormData extends YesNoDontKnowFormData>(intl: IntlShape) =>
  [YesNoDontKnowEnum.YES, YesNoDontKnowEnum.NO, YesNoDontKnowEnum.I_DONT_KNOW].map(option => {
    const choice: Choice<FormData> = {
      id: option,
      label: intl.formatMessage({ id: `models.careProfile.yesNoDontKnowOptions.${option}` }),
      value: option as PathValue<FormData, Path<FormData>>
    };
    return choice;
  });

const optionsForYesNo = (intl: IntlShape) => {
  const options = [YesNoDontKnowEnum.YES, YesNoDontKnowEnum.NO];

  return translateOptions(options, intl, "models.careProfile.yesNoDontKnowOptions");
};

export const durationOptions = (intl: IntlShape) =>
  [ConsultDurationEnum.THIRTY_MINUTES, ConsultDurationEnum.ONE_HOUR].map(option => ({
    label: intl.formatMessage({ id: `models.duration.${option}` }),
    value: option
  }));

export const contactMethodOptions = (intl: IntlShape) =>
  [ContactMethodEnum.ZOOM, ContactMethodEnum.PHONE].map(option => ({
    label: intl.formatMessage({ id: `pages.consults.contactMethod.${option}` }),
    value: option
  }));

export const orderedCareProfileUseCases = [
  CareProfileUseCaseEnum.PREGNANCY,
  CareProfileUseCaseEnum.INFANT,
  CareProfileUseCaseEnum.CHILD,
  CareProfileUseCaseEnum.ADULT,
  CareProfileUseCaseEnum.SELF,
  CareProfileUseCaseEnum.AGING_LOVED_ONE,
  CareProfileUseCaseEnum.GRIEVING
];

export const optionsForCareProfileActionableFieldValue = (
  careProfileField: CareProfileActionableFieldEnum | null,
  intl: IntlShape
) => {
  if (careProfileField === CareProfileActionableFieldEnum.CAREGIVER_TYPES) {
    return optionsForCaregiverType(intl);
  } else if (careProfileField === CareProfileActionableFieldEnum.COGNITIVE_STATUSES) {
    return optionsForCognitiveStatus(intl);
  } else if (careProfileField === CareProfileActionableFieldEnum.CURRENT_CARE_TRANSITIONS) {
    return optionsForCurrentCareTransition(intl);
  } else if (careProfileField === CareProfileActionableFieldEnum.EMOTIONAL_CONCERNS) {
    return optionsForEmotionalConcern(intl);
  } else if (careProfileField === CareProfileActionableFieldEnum.FALL_COUNT) {
    return optionsForFallCount(intl);
  } else if (careProfileField === CareProfileActionableFieldEnum.FINANCIAL_MANAGER) {
    return optionsForFinancialManager(intl);
  } else if (careProfileField === CareProfileActionableFieldEnum.GUIDANCE_TYPE) {
    return optionsForGuidanceType(intl);
  } else if (careProfileField === CareProfileActionableFieldEnum.HEALTH_INSURANCE_TYPES) {
    return optionsForHealthInsuranceType(intl);
  } else if (careProfileField === CareProfileActionableFieldEnum.INSURANCE_TYPES) {
    return optionsForInsuranceType(intl);
  } else if (careProfileField === CareProfileActionableFieldEnum.LEGAL_HELP_NEEDED) {
    return optionsForYesNo(intl);
  } else if (careProfileField === CareProfileActionableFieldEnum.MARITAL_STATUS) {
    return optionsForMaritalStatus(intl);
  } else if (careProfileField === CareProfileActionableFieldEnum.MEDICATIONS_AFFORDABLE) {
    return optionsForYesNoDontKnow(intl);
  } else if (careProfileField === CareProfileActionableFieldEnum.PROVIDER_FINANCIAL_CONCERNS) {
    return optionsForProviderFinancialConcerns(intl);
  } else if (careProfileField === CareProfileActionableFieldEnum.RECENT_HOSPITALIZATION) {
    return optionsForYesNo(intl);
  } else if (careProfileField === CareProfileActionableFieldEnum.RELIGION) {
    return optionsForReligion(intl);
  } else if (careProfileField === CareProfileActionableFieldEnum.RESIDENCE_TYPE) {
    return optionsForResidenceType(intl);
  } else if (careProfileField === CareProfileActionableFieldEnum.SENSE_OF_FULFILLMENT) {
    return optionsForSenseOfFulfillment(intl);
  } else if (careProfileField === CareProfileActionableFieldEnum.SOCIAL_SATISFACTION) {
    return optionsForSocialSatisfaction(intl);
  } else if (careProfileField === CareProfileActionableFieldEnum.STABILITY_SUPPORTS) {
    return optionsForStabilitySupport(intl);
  } else if (careProfileField === CareProfileActionableFieldEnum.TYPES_OF_SUPPORT_NEEDED) {
    return optionsForTypesOfSupportNeeded(intl);
  } else if (careProfileField === CareProfileActionableFieldEnum.URGENCY) {
    return optionsForUrgency(intl);
  } else if (careProfileField === CareProfileActionableFieldEnum.USE_CASE) {
    return optionsForUseCase(intl);
  }

  return [];
};
