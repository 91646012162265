import { IonContent, IonHeader, IonModal, IonText, IonTitle, IonToolbar } from "@ionic/react";
import {
  ComponentProps,
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";

import styles from "@components/v1/Modal.module.css";
import Heading2 from "@components/v1/typography/Heading2";
import CircleButton from "@components/v2/buttons/CircleButton";
import { Opener } from "@hooks/useOpener";
import { isMobile } from "@utils/platformUtils";

type ChildrenData = {
  modalDisplayed: boolean;
};

type Props = Omit<
  ComponentProps<typeof IonModal>,
  "children" | "isOpen" | "onDidDismiss" | "onDidPresent" | "onWillDismiss" | "onWillPresent"
> & {
  children: (data: ChildrenData) => ReactNode;
  contentClassName?: string;
  fullHeight?: boolean;
  fullWidth?: boolean;
  fullscreen?: boolean;
  hideHeader?: boolean;
  hideTitleBorder?: boolean;
  opener: Opener;
  smallModal?: boolean;
  title?: string;
  useMobileStyle?: boolean;
};

export type ModalElement = {
  scrollToTop: () => void;
};

const ModalSubcomponent: ForwardRefRenderFunction<ModalElement, Props> = (
  {
    children,
    className,
    contentClassName,
    fullHeight = false,
    fullWidth = false,
    fullscreen = false,
    hideHeader = false,
    hideTitleBorder,
    onClick,
    onIonModalDidDismiss,
    onIonModalDidPresent,
    onIonModalWillDismiss,
    onIonModalWillPresent,
    opener,
    smallModal = false,
    title,
    useMobileStyle = true,
    ...ionModalProps
  },
  ref
) => {
  const contentRef = useRef<HTMLIonContentElement>(null);

  useImperativeHandle(ref, () => ({
    scrollToTop: () => {
      contentRef.current?.scrollToTop(2000);
    }
  }));

  const [modalDisplayed, setModalDisplayed] = useState<boolean>(false);

  // dismiss the modal on unmount
  useEffect(
    () => () => {
      opener.close();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const classNames = [styles.roundModal];
  if (className) classNames.push(className);
  if (fullscreen) classNames.push("fullscreen");
  if (isMobile()) classNames.push(styles.mobileModal);
  if (smallModal) classNames.push("smallModal");

  const headerClassNames = ["ion-no-border"];

  if (isMobile()) {
    headerClassNames.push(styles.mobileHeader);
  }

  if (hideTitleBorder) {
    headerClassNames.push(styles.headerWithoutBorder);
  } else {
    headerClassNames.push(styles.header);
  }

  const contentWrapperClassNames = [];

  if (fullWidth) {
    contentWrapperClassNames.push(styles.fullWidthContentWrapper);
  } else if (hideTitleBorder) {
    contentWrapperClassNames.push(styles.lessPaddingContentWrapper);
  } else if (fullHeight) {
    contentWrapperClassNames.push(styles.fullHeightContentWrapper);
  } else {
    contentWrapperClassNames.push(styles.contentWrapper);
  }

  if (isMobile()) {
    contentWrapperClassNames.push(styles.mobileContentWrapper);
  }

  if (contentClassName) contentWrapperClassNames.push(contentClassName);

  const breakpointProps: Pick<
    ComponentProps<typeof IonModal>,
    "breakpoints" | "handle" | "handleBehavior" | "initialBreakpoint"
  > = {};
  if (isMobile() && useMobileStyle) {
    breakpointProps.breakpoints = [1];
    breakpointProps.handleBehavior = "none";
    breakpointProps.initialBreakpoint = 1;
    breakpointProps.handle = false;
  }

  return (
    <IonModal
      {...breakpointProps}
      {...ionModalProps}
      className={classNames.join(" ")}
      isOpen={opener.isOpen}
      onClick={event => {
        if (onClick) onClick(event);
        event.stopPropagation();
      }}
      onIonModalDidDismiss={event => {
        opener.close();
        setModalDisplayed(false);
        if (onIonModalDidDismiss) onIonModalDidDismiss(event);
      }}
      onIonModalDidPresent={event => {
        opener.open();
        setModalDisplayed(true);
        if (onIonModalDidPresent) onIonModalDidPresent(event);
      }}
      onIonModalWillDismiss={event => {
        setModalDisplayed(false);
        if (onIonModalWillDismiss) onIonModalWillDismiss(event);
      }}
      onIonModalWillPresent={event => {
        if (onIonModalWillPresent) onIonModalWillPresent(event);
      }}
    >
      {modalDisplayed && (
        <>
          {!hideHeader && (
            <IonHeader className={headerClassNames.join(" ")}>
              {isMobile() ? (
                <div className={`flex margin-20-top align-center items-center ${styles.mobileTitle}`}>
                  <div />
                  <Heading2 color="black">{title}</Heading2>
                  <div className="flex justify-end">
                    <CircleButton
                      ariaLabelKey="accessibility.ariaLabels.close"
                      color="gray-300"
                      fill="clear"
                      icon="xmark"
                      onClick={opener.close}
                      size="large"
                    />
                  </div>
                </div>
              ) : (
                <IonToolbar className={styles.titleToolbar}>
                  {title && (
                    <IonTitle className={styles.title}>
                      <IonText>{title}</IonText>
                    </IonTitle>
                  )}
                  <CircleButton
                    ariaLabelKey="accessibility.ariaLabels.close"
                    className="ion-margin-end"
                    data-test="close-modal"
                    fill="solid"
                    icon="xmark"
                    onClick={opener.close}
                    slot="end"
                  />
                </IonToolbar>
              )}
            </IonHeader>
          )}
          <IonContent ref={contentRef}>
            <div className={contentWrapperClassNames.join(" ")}>{children({ modalDisplayed })}</div>
          </IonContent>
        </>
      )}
    </IonModal>
  );
};

const Modal = forwardRef(ModalSubcomponent);

export default Modal;
